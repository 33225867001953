import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router";
import { DadosRelatorio } from "../../model/dadosRelatorio";
import { RelatorioServico } from "../../servicos/relatorio/relatorio.servico";
import { loadCldr, L10n } from "@syncfusion/ej2-base";


//variavel para utilizar jquery
declare var $: any;
declare var require: any;

loadCldr(
  require("cldr-data/main/pt/numbers.json"),
  require("cldr-data/main/pt/ca-gregorian.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/pt/timeZoneNames.json"),
  require('cldr-data/supplemental/weekdata.json') // To load the culture based first day of week
);

@Component({
  selector: "app-sabores-relatorio",
  templateUrl: "./sabores.relatorio.component.html",
  styleUrls: ["./sabores.relatorio.component.css"]
})
export class SaboresRelatorioComponent implements OnInit {
  constructor(private router: Router, private relatorioServico: RelatorioServico, public datepipe: DatePipe) {

  }

  public dadosRelatorio: DadosRelatorio[];
  public ativar_spinner: boolean = false;
  public minDate: Date = new Date("01/01/2021");
  public maxDate: Date = new Date("12/31/2099");
  public dateValue: Date = new Date();
  public dataInicio: Date = new Date();
  public dataFim: Date = new Date();

  ngOnInit(): void {
    $(() => {
      $(document).ready(function () {

      })
    });
    
  }

  gerarRelatorio() {
    this.ativar_spinner = true;
    var relatorio = new DadosRelatorio();
    relatorio.dataInicio = this.dataInicio;
    relatorio.dataFim = this.dataFim;

    this.relatorioServico.obterVendasSabores(relatorio)
      .subscribe(
        relatorio => {
          this.relatorioServico.gerarExcelSabores(relatorio);
          this.ativar_spinner = false;
        },
        err => {
          console.log(err.error);
          this.ativar_spinner = false;
        }
      )
  }
}
