import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Ingrediente } from "../../model/ingrediente";

@Injectable({
  providedIn: "root"
})
export class IngredienteServico implements OnInit {
  
  private baseURL: string;
  public ingredientes: Ingrediente[];

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {
    this.ingredientes = [];
  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  public cadastrar(ingrediente: Ingrediente): Observable<Ingrediente> {
    return this.http.post<Ingrediente>(this.baseURL + "api/ingrediente", JSON.stringify(ingrediente), { headers: this.headers });
  }

  public salvar(ingrediente: Ingrediente): Observable<Ingrediente> {
    return this.http.post<Ingrediente>(this.baseURL + "api/ingrediente/salvar", JSON.stringify(ingrediente), { headers: this.headers });
  }

  public excluir(ingrediente: Ingrediente): Observable<Ingrediente[]> {
    return this.http.post<Ingrediente[]>(this.baseURL + "api/ingrediente/excluir", JSON.stringify(ingrediente), { headers: this.headers });
  }

  public obterTodosIngredientes(): Observable<Ingrediente[]> {
    return this.http.get<Ingrediente[]>(this.baseURL + "api/ingrediente");
  }

  public obterIngrediente(ingrediente: Ingrediente): Observable<Ingrediente> {
    return this.http.get<Ingrediente>(this.baseURL + "api/ingrediente/obterPorId" + ingrediente.id);
  }

  public enviarArquivo(arquivoSelecionado: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append("arquivoEnviado", arquivoSelecionado, arquivoSelecionado.name);
    return this.http.post<string>(this.baseURL + "api/ingrediente/enviarArquivo", formData);
  }
}
