import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Cliente } from "../../model/cliente";
import { EnderecoViaCep } from "../../model/enderecoViaCep";
import { Telefone } from "../../model/telefone";
import { Pedido } from "../../model/pedido";
import { InjecaoSaldo } from "../../model/injecaoSaldo";
import { FiltroCliente } from "../../model/filtroCliente";
import { Endereco } from "../../model/endereco";


@Injectable({
  providedIn: "root"
})

export class ClienteServico {

  private baseURL: string; 

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  public buscaClientePorTelefone(telefone: Telefone): Observable<Cliente> {

    return this.http.post<Cliente>(this.baseURL + "api/cliente/BuscaClientePorTelefone", telefone, { headers: this.headers });

  }
  

  public salvarCliente(cliente: Cliente): Observable<Cliente> {

    return this.http.post<Cliente>(this.baseURL + "api/cliente/SalvarCliente", cliente, { headers: this.headers });

  }

  public consultaCep(cep: string): Observable<EnderecoViaCep> {
    debugger;
    // Website you wish to allow to connect
    //this.headers.append('Access-Control-Allow-Origin', 'https://localhost:44367');

    //// Request methods you wish to allow
    //this.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');

    //// Request headers you wish to allow
    //this.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

    //// Set to true if you need the website to include cookies in the requests sent
    //// to the API (e.g. in case you use sessions)
    //this.headers.append('Access-Control-Allow-Credentials', 'true');

    //this.headers.append('content-type', 'application/json');

    //return this.http.get<EnderecoViaCep>(`https://viacep.com.br/ws/${cep}/json`, { headers: { 'content-type': 'application/json;charset=utf-8'} });

    return this.http.get<EnderecoViaCep>(this.baseURL + 'api/cliente/busca-cep-cliente/' + cep);
  }

  public buscaSaldo(id: number):Observable<number> {
    return this.http.get<number>(this.baseURL + `api/cliente/buscaSaldo/${id}`);
  }

  public injetarSaldo(injecaoSaldo: InjecaoSaldo): Observable<InjecaoSaldo> {

    return this.http.post<InjecaoSaldo>(this.baseURL + "api/cliente/InjetarSaldo", injecaoSaldo, { headers: this.headers });

  }

  public buscaClientes(filtro: FiltroCliente): Observable<Cliente[]> {
    return this.http.post<Cliente[]>(this.baseURL + "api/cliente/busca-clientes/", filtro, { headers: this.headers });
  }

  public atualizaEndereco(endereco: Endereco): Observable<true> {
    return this.http.post<true>(this.baseURL + "api/cliente/atualiza-endereco/", endereco, { headers: this.headers });
  }

  public atualizaCliente(cliente: Cliente): Observable<true> {
    return this.http.post<true>(this.baseURL + "api/cliente/atualiza-cliente/", cliente, { headers: this.headers });
  }

  public atualizaTelefone(telefone: string, telefoneAtual: Telefone): Observable<true> {
    return this.http.post<true>(this.baseURL + "api/cliente/atualiza-telefone/" + telefone, telefoneAtual, { headers: this.headers });
  }
}
