import { Cliente } from "./cliente";

export class Endereco {
  id: number;
  logradouro: string;
  numero: number;
  complemento: string;
  bairro: string;
  cep: string;
  pontoReferencia: string;
  cidade: string;
  uf: string;
  clienteId: number;
  cliente: Cliente;
  distancia: number;
  selecionado: boolean;
  lat: any;
  lng: any;
}
