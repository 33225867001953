import { Component, OnInit} from "@angular/core";
import { ConfiguracaoSistemaServico } from "../servicos/configuracaoSistema/configuracaoSistema.servico";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-url-shortener',
  template: ''
})

export class UrlShortenerComponent implements OnInit {
  constructor(private configuracaoSistemaServico: ConfiguracaoSistemaServico, private route: ActivatedRoute) {

  }

  ngOnInit(): void {


    this.configuracaoSistemaServico.shortlinkObter(this.route.snapshot.params['token'])
      .subscribe(
        data => {

          window.location.href = data.url;

        });

  }

}
