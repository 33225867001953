import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TruncateModule } from 'ng2-truncate';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AppComponent } from './app.component';
import { GuardaRotas } from './autorizacao/guarda.rotas';
import { FazPedidoComponent } from './fluxo-pedidos/faz-pedido/faz-pedido.component';
import { IdentificaClienteComponent } from './fluxo-pedidos/identifica-cliente/identifica-cliente.component';
import { ResumoPedidoComponent } from './fluxo-pedidos/resumo-pedido/resumo-pedido.component';
import { HomeComponent } from './home/home.component';
import { IngredienteComponent } from './ingrediente/ingrediente.component';
import { PesquisaIngredienteComponent } from './ingrediente/pesquisa/pesquisa.ingrediente.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { EfetivarPedidoComponent } from './pedido/efetivar/efetivar.pedido.component';
import { PesquisaPedidoComponent } from './pedido/pesquisa/pesquisa.pedido.component';
import { ProdutoPedidoComponent } from './pedido/produto/produto.pedido.component';
import { CadastroProdutoComponent } from './produto/cadastro/cadastro.produto.component';
import { PesquisaProdutoComponent } from './produto/pesquisa/pesquisa.produto.component';
import { CadastroSaborComponent } from './sabor/cadastro/cadastro.sabor.component';
import { PesquisaSaborComponent } from './sabor/pesquisa/pesquisa.sabor.component';
import { ClienteServico } from './servicos/cliente/cliente.servico';
import { IngredienteServico } from './servicos/ingrediente/ingrediente.servico';
import { ProdutoServico } from './servicos/produto/produto.servico';
import { SaborServico } from './servicos/sabor/sabor.servico';
import { UsuarioServico } from './servicos/usuario/usuario.servico';
import { CadastroUsuarioComponent } from './usuario/cadastro/cadastro.usuario.component';
import { LoginComponent } from './usuario/login/login.component';
import { DistanceMatrixServico } from './servicos/distance-matrix/distance-matrix.servico';
import { DatePipe } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { PedidoServico } from './servicos/pedido/pedido.servico';
import { PesquisaEntregadorComponent } from './entregador/pesquisa/pesquisa.entregador.component';
import { EntregadorServico } from './servicos/entregador/entregador.servico';
import { CadastrarEntregadorComponent } from './entregador/cadastro/cadastro.entregador.component';
import { EntregasConsultaComponent } from './fluxo-entregas/consulta/consulta.component';
import { TaxaEntregaComponent } from './taxa-entrega/taxa-entrega.component';
import { TaxaServico } from './servicos/taxas/taxa.servico';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThermalPrintModule, PrintService } from 'ng-thermal-print';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { TextMaskModule } from 'angular2-text-mask';
import { MovimentacaoFinanceiraComponent } from './fluxo-caixa/movimentacao-financeira/movimentacao-financeira.component';
import { RegistroFinanceiroComponent } from './fluxo-caixa/registro-financeiro/registro-financeiro.component';
import { RegistroFinanceiroServico } from './servicos/financeiro/registro-financeiro.servico';
import { LoadingComponent } from './loading/loading.component';
import { DetalhePedidoComponent } from './fluxo-pedidos/detalhe-pedido/detalhe-pedido.component';
import { RegistrarEntregasComponent } from './fluxo-entregas/registrar/registrar.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PesquisaClienteComponent } from './cliente/pesquisa/pesquisa.cliente.component';
import { PesquisaRelatorioComponent } from './relatorio/pesquisa/pesquisa.relatorio.component';
import { RelatorioServico } from './servicos/relatorio/relatorio.servico';
import { SaboresRelatorioComponent } from './relatorio/sabores/sabores.relatorio.component';
import { MercadoriaServico } from './servicos/mercadoria/mercadoria.servico';
import { CadastroMercadoriaComponent } from './mercadoria/cadastro/cadastro.mercadoria.component';
import { PesquisaMercadoriaComponent } from './mercadoria/pesquisa/pesquisa.mercadoria.component';
import { CadastroReceitaComponent } from './receita/cadastro/cadastro.receita.component';
import { ReceitaServico } from './servicos/receita/receita.servico';
import { PesquisaReceitaComponent } from './receita/pesquisa/pesquisa.receita.component';
// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PlataformaRelatorioComponent } from './relatorio/plataforma/plataforma.relatorio.component';
import { ConfiguracaoSistemaServico } from './servicos/configuracaoSistema/configuracaoSistema.servico';
import { EntregadoresRelatorioComponent } from './relatorio/entregadores/entregadores.relatorio.component';
import { UrlShortenerComponent } from './url-shortener/url-shortener.component'


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    IngredienteComponent,
    LoginComponent,
    CadastroUsuarioComponent,
    PesquisaIngredienteComponent,
    CadastroSaborComponent,
    PesquisaSaborComponent,
    CadastroProdutoComponent,
    PesquisaProdutoComponent,
    PesquisaPedidoComponent,
    ProdutoPedidoComponent,
    EfetivarPedidoComponent,
    IdentificaClienteComponent,
    FazPedidoComponent,
    ResumoPedidoComponent,
    PesquisaEntregadorComponent,
    CadastrarEntregadorComponent,
    EntregasConsultaComponent,
    TaxaEntregaComponent,
    MovimentacaoFinanceiraComponent,
    RegistroFinanceiroComponent,
    LoadingComponent,
    DetalhePedidoComponent,
    RegistrarEntregasComponent,
    PesquisaClienteComponent,
    PesquisaRelatorioComponent,
    SaboresRelatorioComponent,
    CadastroMercadoriaComponent,
    PesquisaMercadoriaComponent,
    CadastroReceitaComponent,
    PesquisaReceitaComponent,
    PlataformaRelatorioComponent,
    EntregadoresRelatorioComponent,
    UrlShortenerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TruncateModule,
    NgSelectModule,
    ThermalPrintModule,
    DragulaModule.forRoot(),
    CurrencyMaskModule,
    TextMaskModule,
    DatePickerModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 't/:token', component: UrlShortenerComponent },
      { path: 'login', component: LoginComponent },
      { path: 'novo-usuario', component: CadastroUsuarioComponent },
      { path: 'ingrediente', component: IngredienteComponent, canActivate: [GuardaRotas] },
      { path: 'pesquisar-ingrediente', component: PesquisaIngredienteComponent, canActivate: [GuardaRotas] },
      { path: 'cadastrar-sabor', component: CadastroSaborComponent, canActivate: [GuardaRotas] },
      { path: 'pesquisar-sabor', component: PesquisaSaborComponent, canActivate: [GuardaRotas] },
      { path: 'cadastrar-produto', component: CadastroProdutoComponent, canActivate: [GuardaRotas] },
      { path: 'pesquisar-produto', component: PesquisaProdutoComponent, canActivate: [GuardaRotas] },
      { path: 'pesquisar-pedido', component: PesquisaPedidoComponent, canActivate: [GuardaRotas] },
      { path: 'produto-pedido', component: ProdutoPedidoComponent, canActivate: [GuardaRotas] },
      { path: 'efetivar-pedido', component: EfetivarPedidoComponent, canActivate: [GuardaRotas] },
      { path: 'identifica-cliente', component: IdentificaClienteComponent, canActivate: [GuardaRotas] },
      { path: 'identifica-cliente/:numeroPedido', component: IdentificaClienteComponent, canActivate: [GuardaRotas] },
      { path: 'faz-pedido/:numeroPedido', component: FazPedidoComponent, canActivate: [GuardaRotas] },
      { path: 'faz-pedido-balcao/:novoPedido', component: FazPedidoComponent, canActivate: [GuardaRotas] },
      { path: 'resumo-pedido/:numeroPedido', component: ResumoPedidoComponent, canActivate: [GuardaRotas] },
      { path: 'pesquisar-entregador', component: PesquisaEntregadorComponent, canActivate: [GuardaRotas]},
      { path: 'cadastrar-entregador', component: CadastrarEntregadorComponent, canActivate: [GuardaRotas]},
      { path: 'cadastrar-entregador/:id', component: CadastrarEntregadorComponent, canActivate: [GuardaRotas]},
      { path: 'consulta-entrega-pedido', component: EntregasConsultaComponent, canActivate: [GuardaRotas] },
      { path: 'consulta-entrega-pedido/:pedidoTelefone', component: EntregasConsultaComponent, canActivate: [GuardaRotas] },
      { path: 'taxa-entrega', component: TaxaEntregaComponent, canActivate: [GuardaRotas] },
      { path: 'movimentacao-financeira', component: MovimentacaoFinanceiraComponent, canActivate: [GuardaRotas] },
      { path: 'registro-financeiro', component: RegistroFinanceiroComponent, canActivate: [GuardaRotas] },
      { path: 'detalhe-pedido', component: DetalhePedidoComponent, canActivate: [GuardaRotas] },
      { path: 'registrar-entregas', component: RegistrarEntregasComponent, canActivate: [GuardaRotas] },
      { path: 'pesquisa-cliente', component: PesquisaClienteComponent, canActivate: [GuardaRotas] },
      { path: 'pesquisa-relatorio', component: PesquisaRelatorioComponent, canActivate: [GuardaRotas] },
      { path: 'sabores-relatorio', component: SaboresRelatorioComponent, canActivate: [GuardaRotas] },
      { path: 'cadastrar-mercadoria', component: CadastroMercadoriaComponent, canActivate: [GuardaRotas] },
      { path: 'pesquisar-mercadoria', component: PesquisaMercadoriaComponent, canActivate: [GuardaRotas] },
      { path: 'cadastrar-receita', component: CadastroReceitaComponent, canActivate: [GuardaRotas] },
      { path: 'pesquisar-receita', component: PesquisaReceitaComponent, canActivate: [GuardaRotas] },
      { path: 'plataforma-relatorio', component: PlataformaRelatorioComponent, canActivate: [GuardaRotas] },
      { path: 'entregadores-relatorio', component: EntregadoresRelatorioComponent, canActivate: [GuardaRotas] }
    ])
  ],
  providers: [UsuarioServico, IngredienteServico, SaborServico, ProdutoServico, ClienteServico, DistanceMatrixServico, DatePipe, PedidoServico, EntregadorServico,
              TaxaServico, PrintService, RegistroFinanceiroServico, RelatorioServico, MercadoriaServico, ReceitaServico, ConfiguracaoSistemaServico],
  bootstrap: [AppComponent]
})
export class AppModule { }

//{ path: 'ingrediente', component: IngredienteComponent, canActivate:[GuardaRotas] },
