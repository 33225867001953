import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ProdutoServico } from "../../servicos/produto/produto.servico";
import { Produto } from "../../model/produto";

@Component({
  selector: "app-pedido",
  templateUrl: "./pesquisa.pedido.component.html",
  styleUrls: ["./pesquisa.pedido.component.css"]
})

export class PesquisaPedidoComponent implements OnInit {
  public produtos: Produto[];
  public ativar_spinner: boolean;

  ngOnInit(): void {

  }

  constructor(private router: Router, private produtoServico: ProdutoServico) {
    this.espera(true);
    this.produtoServico.obterTodosProdutos()
      .subscribe(
        produtos => {
          this.produtos = produtos;
          this.espera(false);
        },
        err => {
          console.log(err.error);
        }
    );
  }

  public abrirProduto(produto: Produto) {
    
    produto.sabores = [];
    localStorage.setItem('produtoDetalhe', JSON.stringify(produto));
    this.router.navigate(['/produto-pedido']);
  }

  public espera(ativa: boolean) {
    this.ativar_spinner = ativa;
  }

}


