import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { DatePipe } from "@angular/common";
import { PedidoServico } from "../../servicos/pedido/pedido.servico";
import { EntregadorServico } from "../../servicos/entregador/entregador.servico";
import { Entregador, ContratoEnum } from "../../model/entregador";
import { List } from 'linqts';
import { Pedido } from "../../model/pedido";
import { PedidoEntregador } from "../../model/pedidoEntregador";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { } from 'googlemaps';
import { ConfiguracaoSistemaServico } from "../../servicos/configuracaoSistema/configuracaoSistema.servico";

//variavel para utilizar jquery
declare var $: any;

@Component({
  selector: "app-fluxo-registrar-entregas",
  templateUrl: "./registrar.component.html",
  styleUrls: ["./registrar.component.css"]
})

export class RegistrarEntregasComponent implements OnInit {
  public entregadorSelecionado: Entregador = new Entregador();
  public entregadorSelecionadoId: number;
  public listaEntregadoresAtivos: Entregador[] = [];
  public ContratoEnum = ContratoEnum;
  public ativar_spinner: boolean = false;
  public mensagem: string;
  public numeroPedido: number;
  public listaPedidosEntregues: PedidoEntregador[] = [];
  public exibirRoteiro: boolean = true;
  public distanciaRoteiro: string;
  public urlRoteiro: string;
  public listaPedidosRoteiro: Pedido[] = [];
  public listaEnderecosRoteiro = [];
  @ViewChild('map') mapElement: any;
  @ViewChild('painel') painelElement: any;
  public directionsService: any;
  public directionsRenderer: any;
  map: google.maps.Map;
  loja: any;
  gapi: any;
  public qrCodeGerado: any = false;
  public temEnderecos: any = false;
  public permiteGerarRoteiro: boolean = false;

  constructor(private datePipe: DatePipe, private pedidoServico: PedidoServico, private entregadorServico: EntregadorServico, private router: Router, private http: HttpClient, private configuracaoSistemaServico: ConfiguracaoSistemaServico) {
    this.preencheListaEntregadores();
  }

  ngOnInit(): void {
    $(() => {
      $(document).ready(function () {

      })
    });

    var self = this;

    this.configuracaoSistemaServico.obterTodos()
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            this.loja = { lat: -0.0, lng: -0.0 };
            data.forEach(config => {

              if (config.descricao == 'PosicaoLatitudeLoja') {
                this.loja.lat = Number(config.valor);
              }
              else if (config.descricao == 'PosicaoLongitudeLoja') {
                this.loja.lng = Number(config.valor);
              }

              if (config.descricao == 'PermiteGerarRoteiro')
                this.permiteGerarRoteiro = config.valor == 'true';

            });

            this.preencherComponentesMap();
          }
        },
        err => {
          console.log(err.error);
        }
      );
  }

  preencheListaEntregadores() {
    var self = this;

    this.entregadorServico.obterTodosEntregadores().subscribe(
      entregadores => {
        self.listaEntregadoresAtivos = new List<Entregador>(entregadores).Where(e => e.ativo == true).ToArray();
        self.listaEntregadoresAtivos.map(e => {
          e.search_label = `${e.nome} ${e.apelido} ${e.telefone} ${e.contrato}`;
          return e;
        });

      },
      erro => { self.mensagem = erro.error; }
    );
  }

  pesquisarPedido(event) {
    this.ativar_spinner = true;

    if (this.entregadorSelecionadoId == null) {
      alert("Selecione um entregador");
      this.ativar_spinner = false;
      return;
    }

    if (this.numeroPedido == null) {
      alert("Informe um pedido");
      this.ativar_spinner = false;
      return;
    }

    if (this.listaPedidosEntregues.some(item => item.pedido.numeroPedidoDia == this.numeroPedido)) {
      alert("Pedido já adicionado na lista abaixo");
      this.ativar_spinner = false;
      return;
    }

    this.pedidoServico.consultaPedidoPorNumero(this.numeroPedido).subscribe(
      pedido => {
        if (pedido != null) {

          if (pedido.balcao) {
            alert("Pedido balcão, não é possível entregar");
            this.ativar_spinner = false;
            return;
          }

          var pedidoEntregador = new PedidoEntregador();

          var entregador = new List<Entregador>(this.listaEntregadoresAtivos).FirstOrDefault(e => e.id == this.entregadorSelecionadoId);

          pedidoEntregador.entregador = entregador;
          pedidoEntregador.pedido = pedido;
          this.numeroPedido = null;
          this.listaPedidosEntregues.push(pedidoEntregador);
        }
        else {
          alert("Pedido não encontrado");
        }
        this.ativar_spinner = false;
      },
      erro => {
        alert(erro.error);
        this.ativar_spinner = false;
      }
    );
  }

  public removerItem(pedidoEntregador: PedidoEntregador) {
    this.listaPedidosEntregues = this.listaPedidosEntregues.filter(pe => pe.pedido.numeroPedidoDia != pedidoEntregador.pedido.numeroPedidoDia);
  }

  salvar() {
    this.ativar_spinner = true;

    for (var pedidoAux of <PedidoEntregador[]>this.listaPedidosEntregues) {
      pedidoAux.pedido.dataDespacho = new Date();
    }

    this.pedidoServico.atualizarEntregadoresEmlote(this.listaPedidosEntregues).subscribe(
      pedido => {

        this.listaPedidosEntregues = [];
        alert("Pedidos atualizados");

        $(() => {
          $('#lstEntregadores').find('input').focus();
        });

        this.ativar_spinner = false;
      },
      erro => {

        alert(erro.error);
        this.ativar_spinner = false;
      }
    );
  }

  customSearchFn(term: string, item: any) {

    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    var ehNumero = !isNaN(Number(term));

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      if (ehNumero) {
        let search = item['codigo'];
        isWordThere.push(search == Number(arr_term));
      }
      else {
        let search = item['search_label'].toLowerCase();
        isWordThere.push(search.indexOf(arr_term) != -1);
      }

    });

    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }

  criarRoteiro() {
    this.exibirRoteiro = true;
    this.listaPedidosRoteiro = [];

    this.listaPedidosEntregues.forEach(lpe => {
      this.listaPedidosRoteiro.push(lpe.pedido);
    });

    this.displayRoute();

    this.qrCodeGerado = false;
  }

  displayRoute() {
    var origin = this.loja;
    var destination = this.loja;
    var service = this.directionsService;
    var display = this.directionsRenderer;

    if (this.listaPedidosRoteiro.length > 0) {
      this.listaEnderecosRoteiro = this.listaPedidosRoteiro.map(function (pedidoSelecionado) {
        var end = pedidoSelecionado.cliente.enderecos[0];
        return { location: `${end.logradouro}, ${end.numero} - ${end.bairro} - ${end.cidade}, ${end.uf}`, stopover: true };
      });

    } else {
      this.listaEnderecosRoteiro = [];
    }

    this.temEnderecos = this.listaPedidosRoteiro.length > 0;

    service
      .route({
        origin: origin,
        destination: destination,
        waypoints: this.listaEnderecosRoteiro,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING
      })
      .then((result) => {
        display.setDirections(result);
      })
      .catch((e) => {
        alert("Não foi possível mostrar a direção para: " + e);
      });

  }

  computeTotalDistance(result) {
    let total = 0;
    const myroute = result.routes[0];

    if (!myroute) {
      this.distanciaRoteiro = "Distância Total: " + total.toString() + " km";
      return;
    }

    let ends = [];

    for (let i = 0; i < myroute.legs.length; i++) {
      total += myroute.legs[i].distance.value;

      if (ends.indexOf(encodeURI(myroute.legs[i].start_address)) < 0 && i > 1)
        ends.push(encodeURI(myroute.legs[i].start_address));

      if (ends.indexOf(encodeURI(myroute.legs[i].end_address)) < 0 && i < myroute.legs.length - 1)
        ends.push(encodeURI(myroute.legs[i].end_address));
    }

    var waypoint_place_ids = result.geocoded_waypoints.map(function (w) { return w.place_id })
    var waypoints = ends;

    total = total / 1000;
    this.distanciaRoteiro = "Distância Total: " + total.toString().replace(".", ",") + " km";
    this.urlRoteiro = `https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${this.loja.lat},${this.loja.lng}&destination=${this.loja.lat},${this.loja.lng}&waypoints=${waypoints.join('%7C')}`;
  }

  gerarQrCode() {
    this.configuracaoSistemaServico.shortlink(this.urlRoteiro).subscribe(data => {
      this.urlRoteiro = data.url;
      this.qrCodeGerado = true;
    });

  }

  copiarLinkRota() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.urlRoteiro;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    alert('Link copiado');
  }

  preencherComponentesMap() {
    var self = this;
    const mapProperties = {
      center: this.loja,
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      clickableIcons: false
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);

    //como resolver o Marker descontinuado
    //https://developers.google.com/maps/documentation/javascript/load-maps-js-api

    new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      position: this.loja,
      map: this.map
    });

    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      draggable: true,
      map: this.map,
      panel: this.painelElement.nativeElement,
    });


    this.directionsRenderer.addListener("directions_changed", () => {
      const directions = self.directionsRenderer.getDirections();
      if (directions) {
        self.computeTotalDistance(directions);
      } else {
        self.distanciaRoteiro = 0 + " km";
      }
    });
  }
}
