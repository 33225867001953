import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { DistanceMatrix } from "../../model/distanceMatrix";


@Injectable({
  providedIn: "root"
})

export class DistanceMatrixServico{

  private baseURL: string;

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  public getDistanceMatrix(destino: string): Observable<DistanceMatrix> {
    return this.http.get<DistanceMatrix>(this.baseURL + `api/DistanceMatrix/${destino}`, { headers: this.headers });
  }


}
