import { Component, OnInit } from "@angular/core";
import { ProdutoServico } from "../../servicos/produto/produto.servico";
import { Produto } from "../../model/produto";
import { Sabor } from "../../model/sabor";
import { SaborServico } from "../../servicos/sabor/sabor.servico";
import { Router } from "@angular/router";
import { CarrinhoComprasPedido } from "../carrinho-compras/carrinho.compras.pedido";
import { SaborProduto } from "../../model/saborProduto";
import { TamanhoPizza } from "../../model/tamanhoPizza";

@Component({
  selector: "produto-app-pedido",
  templateUrl: "./produto.pedido.component.html",
  styleUrls: ["./produto.pedido.component.css"]
})

export class ProdutoPedidoComponent implements OnInit {
  public produto: Produto;
  public saboresDisponiveis: Sabor[];
  public carrinhoCompras: CarrinhoComprasPedido;
  public saborSelecionado: Sabor;
  public todosSabores: boolean;
  public tamanhosPizza: TamanhoPizza[];

  ngOnInit(): void {
    this.carrinhoCompras = new CarrinhoComprasPedido();
    this.saborSelecionado = new Sabor();
    this.todosSabores = false;
    var produtoDetalhe = localStorage.getItem('produtoDetalhe');
    if (produtoDetalhe) {
      this.produto = JSON.parse(produtoDetalhe);
      this.validarPizzaTamanhoCompleto();

      this.carregarSabores();
    }

    this.recarregarArrayTamanhoPizza();
  }

  constructor(private produtoServico: ProdutoServico, private saborServico: SaborServico, private router: Router) {

  }

  public carregarSabores() {
    if (!this.todosSabores && (this.produto.tipoProdutoId == 1 || this.produto.tipoProdutoId == 2 || this.produto.tipoProdutoId == 3)) {
      this.saborServico.obterTodosSabores()
        .subscribe(
          sabores => {
            if (this.produto.tipoProdutoId == 1) {
              this.saboresDisponiveis = sabores.filter(s => s.tipoSabor == 1).map((s) => {
                s.nome = s.nome + ' | ' + s.precoCheio.toLocaleString("en-US", {
                  style: "currency",
                  currency: "BRL"
                }); return s;
              });
            }
            else {
              this.saboresDisponiveis = sabores.filter(s => s.tipoSabor == 2).map((s) => {
                s.nome = s.nome + ' | ' + s.precoCheio.toLocaleString("en-US", {
                  style: "currency",
                  currency: "BRL"
                }); return s;
              });
            }
          },
          err => {
            console.log(err.error);
          }
        );
    }
  }

  public comprar() {
    this.carrinhoCompras.adicionar(this.produto);
    this.router.navigate(["/efetivar-pedido"]);
  }

  public atualizarPreco(sabor: Sabor, quantidade: number) {

    this.saborServico.obterSabor(sabor)
      .subscribe(
        s => {
          var produtoDetalhe = localStorage.getItem('produtoDetalhe');
          if (produtoDetalhe) {
            this.produto = JSON.parse(produtoDetalhe);
          }

          if (!this.produto.sabores) {
            this.produto.sabores = [];
          }

          //this.produto.preco = 0;

          s.quantidade = quantidade;

          //for (var saborAdicionado of this.produto.sabores) {
          //  this.produto.preco += saborAdicionado.precoCalculado;
          //}

          //this.produto.preco += (s.precoCheio * s.quantidade);

          localStorage.setItem('produtoDetalhe', JSON.stringify(this.produto));
        },
        err => {
          console.log(err.error);
        }
      );
  }

  public adicionarSabor(sabor: Sabor) {
    this.saborServico.obterSabor(sabor)
      .subscribe(
        s => {
          var produtoDetalhe = localStorage.getItem('produtoDetalhe');
          if (produtoDetalhe) {
            this.produto = JSON.parse(produtoDetalhe);
          }

          if (!this.produto.sabores) {
            this.produto.sabores = [];
          }

          this.removerSabor(sabor);

          s.quantidade = sabor.quantidade;
          s.precoCalculado = s.precoCheio * s.quantidade;
          this.produto.sabores.push(s);

          this.validarPizzaTamanhoCompleto();

          this.recarregarArrayTamanhoPizza();
          localStorage.setItem('produtoDetalhe', JSON.stringify(this.produto));
          this.saborSelecionado = new Sabor();
        },
        err => {
          console.log(err.error);
        }
      );
  }

  removerSabor(sabor: Sabor): void {
    this.produto.sabores = this.produto.sabores.filter(({ id }) => id !== sabor.id);

    localStorage.setItem('produtoDetalhe', JSON.stringify(this.produto));

    this.recarregarArrayTamanhoPizza();
    this.validarPizzaTamanhoCompleto();
    this.carregarSabores();
  }

  recarregarArrayTamanhoPizza() {
    this.tamanhosPizza = [];
    var sum = this.produto.sabores.reduce((sum, saborAtual) => sum + Number(saborAtual.quantidade), 0);
    sum = Number(sum.toFixed(2));

    if (sum == 0) {
      this.tamanhosPizza.push(new TamanhoPizza(1, 'Inteira'));
      this.tamanhosPizza.push(new TamanhoPizza(0.5, '2 sabores'));
      this.tamanhosPizza.push(new TamanhoPizza(0.3, '3 sabores'));
      this.tamanhosPizza.push(new TamanhoPizza(0.25, '4 sabores'));
    }

    if (sum == 0.25 || sum == 0.5) {
      this.tamanhosPizza.push(new TamanhoPizza(0.5, '2 sabores'));
      this.tamanhosPizza.push(new TamanhoPizza(0.25, '4 sabores'));
    }

    if (sum == 0.75) {
      this.tamanhosPizza.push(new TamanhoPizza(0.25, '4 sabores'));
    }

    if (sum == 0.3 || sum == 0.6)
      this.tamanhosPizza.push(new TamanhoPizza(0.3, '3 sabores'));
  }

  validarPizzaTamanhoCompleto() {
    var sum = this.produto.sabores.reduce((sum, saborAtual) => sum + Number(saborAtual.quantidade), 0);
    sum = Number(sum.toFixed(2));

    if (sum == 0.9 || sum == 1) {
      this.todosSabores = true;
    }
    else {
      this.todosSabores = false;
    }

    if (sum == 0) {
      this.produto.preco = 0;
    }
    else {
      this.produto.preco = 0;
      for (var saborAdicionado of this.produto.sabores) {
        this.produto.preco += saborAdicionado.precoCalculado;
      }
    }
  }
}
