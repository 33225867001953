export enum MedidaEnum {
  NaoDefinido = 0,
  Litro = 1,
  Unidade = 2,
  Quilo = 3
}

export class Mercadoria {
  id: number;
  descricao: string;
  quantidade: number;
  medida: MedidaEnum;
}
