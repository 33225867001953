import { Component, OnInit } from "@angular/core";
import { CarrinhoComprasPedido } from "../carrinho-compras/carrinho.compras.pedido";
import { Produto } from "../../model/produto";

@Component({
  selector: "efetivar-pedido",
  templateUrl: "./efetivar.pedido.component.html",
  styleUrls: ["./efetivar.pedido.component.css"]
})

export class EfetivarPedidoComponent implements OnInit {
  public carrinhoCompras: CarrinhoComprasPedido;
  public produtos: Produto[];
  public total: number;

  ngOnInit(): void {
    this.carrinhoCompras = new CarrinhoComprasPedido();
    this.produtos = this.carrinhoCompras.obterProdutos();
    this.atualizarTotal();
  }

  public atualizarPreco(produto: Produto, quantidade: number) {
    if (!produto.precoOriginal) {
      produto.precoOriginal = produto.preco;
    }

    if (quantidade <= 0) {
      quantidade = 1;
      produto.quantidade = quantidade;
    }

    produto.preco = produto.precoOriginal * quantidade;

    this.carrinhoCompras.atualizar(this.produtos);
    this.atualizarTotal();
  }

  public remover(produto: Produto) {
    this.carrinhoCompras.removerProduto(produto);
    this.produtos = this.carrinhoCompras.obterProdutos();
    this.atualizarTotal();
  }

  public atualizarTotal() {
    this.total = this.produtos.reduce((acc, produto) => acc + produto.preco, 0);
  }
}
