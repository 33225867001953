import { Component, OnInit, NgZone, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Produto } from "../../model/produto";
import { Pedido, StatusEnum, FormaPagamentoEnum } from "../../model/pedido";
import { PedidoConstants } from "../../constants/pedido.constants";
import { fraction, format } from "mathjs";
import { PedidoServico } from "../../servicos/pedido/pedido.servico";
import { ItemPedido } from "../../model/itemPedido";
import { List } from "linqts";
import { Endereco } from "../../model/endereco";
import { UsuarioServico } from "../../servicos/usuario/usuario.servico";
import { TaxaServico } from "../../servicos/taxas/taxa.servico";
import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { PrintDriver } from 'ng-thermal-print/lib/drivers/PrintDriver';
import { DadosImpressao } from "../../model/dadosImpressao";
import { ConfiguracaoSistemaServico } from "../../servicos/configuracaoSistema/configuracaoSistema.servico";

//variavel para utilizar jquery
declare var $: any;


@Component({
  selector: "app-resumo-pedido",
  templateUrl: "./resumo-pedido.component.html",
  styleUrls: ["./resumo-pedido.component.css"]
})

export class ResumoPedidoComponent implements OnInit {

  public ativar_spinner: boolean;
  public numeroPedido: number;
  public pedido: Pedido;
  public chaveLocalStorage: string;
  public subTotalPedido: number = 0;
  public totalPedido: number = 0;
  public partesPizza: number = 0;
  public formaPagamento: string = "0";
  public trocoPara: number = 0;
  public balcao: boolean = false;
  public mensagem: string = "";
  private latlng: google.maps.LatLng;
  private printerStatus: boolean = false;
  private usbPrintDriver: UsbDriver;
  public somenteTaxaBalcao: boolean = false;
  public telefoneCliente: string = "";
  public valorDesconto: number = 0;
  public multiplicadorTaxa: number = 1;


  constructor(private router: Router, private route: ActivatedRoute, private pedidoServico: PedidoServico, private usuarioServico: UsuarioServico,
    private taxaServico: TaxaServico, private zone: NgZone, private printService: PrintService, private configuracaoSistemaServico: ConfiguracaoSistemaServico) {
    //this.usbPrintDriver = new UsbDriver();
    //this.printService.isConnected.subscribe(result => {
    //  debugger;
    //  this.printerStatus = result;
    //  if (result) {
    //    console.log('Connected to printer!!!');
    //  } else {
    //    console.log('Not connected to printer.');
    //  }
    //});
  }

  ngOnInit(): void {
    var self = this;
    //recupera o numeroPedido se vier pela url
    this.route.params.subscribe(
      params => {
        if (params.numeroPedido !== undefined) {
          self.numeroPedido = parseInt(params.numeroPedido);
          self.chaveLocalStorage = PedidoConstants.CHAVE_PEDIDO_LOCAL_STORAGE.format({ numeroPedido: self.numeroPedido });
          var p = <Pedido>JSON.parse(localStorage.getItem(self.chaveLocalStorage));
          if (p) {
            if (p.cliente != null && p.cliente.telefones != null && p.cliente.telefones.length > 0) {
              this.telefoneCliente = " - Telefone: (" + p.cliente.telefones[0].ddd + ")" + p.cliente.telefones[0].numero;
            }
            self.pedido = p;
            p.itensPedido.forEach((itemPedido, i) => {
              self.totalPedido += parseFloat(itemPedido.preco.toFixed(2));
            });
            if (p.cliente != null && p.cliente.enderecos != null && p.cliente.enderecos.length > 0) {
              self.pedido.cliente.enderecos = [new List<Endereco>(self.pedido.cliente.enderecos).FirstOrDefault(e => e.selecionado == true)];
            }
            
            p = self.aplicarDesconto(p);

            self.balcao = p.balcao;

            this.somenteTaxaBalcao = p.cliente.somenteTaxaBalcao;

            if (p.formaPagamento != undefined && p.formaPagamento != null) {
              self.formaPagamento = p.formaPagamento.toString();
            }
            else {
              p.formaPagamento = FormaPagamentoEnum.CartaoCredito;
              self.formaPagamento = "1";

              self.pagamentoCartao();
              self.setFormaPagamento();
            }

            if (parseInt(self.formaPagamento) == 4)
              this.focoTrocoPara();
          }
        }
      });

    this.configuracaoSistemaServico.obterTodos()
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            data.forEach(config => {
              if (config.descricao == 'MultiplicadorTaxa') {
                this.multiplicadorTaxa = Number(config.valor);
              }
            });
          }
        },
        err => {
          console.log(err.error);
        }
      );
  }

  private aplicarDesconto(pedido: Pedido): Pedido {
    
    this.subTotalPedido = this.totalPedido;

    if (pedido.cliente.saldo != 0) {
      if (pedido.cliente.saldo > 0) {
        if (this.totalPedido >= pedido.cliente.saldo) {
          this.valorDesconto = pedido.cliente.saldo;
          this.totalPedido -= pedido.cliente.saldo;
        }
        else {
          this.valorDesconto = this.totalPedido;
          pedido.cliente.saldo -= this.totalPedido;
          this.totalPedido = 0;
        }
      }
      else {
        this.totalPedido += (pedido.cliente.saldo * -1);
        this.valorDesconto = (pedido.cliente.saldo * -1);
      }
    }

    return pedido;
  }

  public voltar() {
    this.espera(true);
    this.router.navigate([`/faz-pedido/${this.numeroPedido}`]);
    this.espera(false);
  }

  public consultaTaxaDistancia(callback: () => void): void {
    this.espera(true);
    var self = this;

    if (this.pedido.balcao == true) {

      if (typeof callback === "function") {
        self.pedido.taxaEntrega = 0;
        callback();
        self.espera(false);
      }

    } else {

      if ((self.pedido.taxaEntrega || 0) == 0) {
        var end = new List<Endereco>(this.pedido.cliente.enderecos).FirstOrDefault(e => e.selecionado == true);
        var enderecoDestino = `${end.logradouro} ${end.numero} ${end.cidade} ${end.uf}`;

        var geoCoder = new google.maps.Geocoder();


        geoCoder.geocode({ address: enderecoDestino }, (results, status) => {

          if (status == "OK") {
            self.latlng = results[0].geometry.location;

            self.taxaServico.consultaTaxaBanco(self.latlng.lat(), self.latlng.lng()).subscribe(
              taxa => {

                if (taxa == null || taxa == undefined || (taxa.id || 0) == 0) {
                  self.mensagem = "Este endereço não está em nossa área de cobertura";
                  if (typeof callback === "function") {
                    callback();
                    self.espera(false);

                  }
                } else {
                  self.mensagem = null;
                  if (typeof callback === "function") {
                    //necessário atualizar com esse zone pra não dar delay no angular
                    self.zone.run(() => {
                      self.pedido.taxaEntrega = taxa.taxaValor;
                      callback();
                      self.espera(false);
                    });
                  }
                }
              });
          }
        });
      }
      else {
        callback();
        self.espera(false);
      }


    }

  }

  public somaPartes(parte: number): boolean {
    this.partesPizza += parte;
    if (this.partesPizza >= 1) {
      this.partesPizza = 0;
      return true;
    }
    return false;
  }

  public obtemFracao(n: number) {
    return format(fraction(n), 14);
  }

  private setFormaPagamento() {
    this.pedido.formaPagamento = parseInt(this.formaPagamento);
    localStorage.setItem(this.chaveLocalStorage, JSON.stringify(this.pedido));
    //this.formaPagamento = formaPagamentoId;
  }

  public pagamentoCartao() {
    this.trocoPara = 0;
    this.setFormaPagamento();
  }

  public focoTrocoPara() {

    var self = this;
    var to = window.setTimeout(function () {
      $('#txtTrocoPara').focus();

      var valor = Math.ceil(self.totalPedido + (self.pedido.balcao ? 0 : self.pedido.taxaEntrega));
      while (valor % 10 > 1) {
        valor++;
      }
      self.trocoPara = valor;
      $('#txtTrocoPara').val(valor);
      clearInterval(to);
    }, 500);

    this.setFormaPagamento();

  }

  public setBalcao() {
    var self = this;

    if (!this.pedido.cliente.somenteTaxaBalcao) {

      this.consultaTaxaDistancia(function () {
        self.totalPedido = 0;
        new List<ItemPedido>(self.pedido.itensPedido).ForEach((oItemPedido) => {
          if (self.pedido.balcao || oItemPedido.tipoProdutoId == 4) {
            oItemPedido.preco = parseFloat((oItemPedido.precoCheio * oItemPedido.quantidade).toFixed(2));
          }
          else {
            oItemPedido.preco = parseFloat(((oItemPedido.precoCheio * oItemPedido.quantidade) * this.multiplicadorTaxa).toFixed(2));
          }

          self.totalPedido += oItemPedido.preco;
        });

        self.pedido = self.aplicarDesconto(self.pedido);

        if (parseInt(self.formaPagamento) == 4) {
          self.focoTrocoPara();
        } else {
          localStorage.setItem(self.chaveLocalStorage, JSON.stringify(self.pedido));
        }
      });
    }
    else {
      //this.pedido.balcao = true;

      self.totalPedido = 0;
      new List<ItemPedido>(self.pedido.itensPedido).ForEach((oItemPedido) => {
        if (self.pedido.balcao || oItemPedido.tipoProdutoId == 4) {
          oItemPedido.preco = parseFloat((oItemPedido.precoCheio * oItemPedido.quantidade).toFixed(2));
        }
        else {
          oItemPedido.preco = parseFloat(((oItemPedido.precoCheio * oItemPedido.quantidade) * this.multiplicadorTaxa).toFixed(2));
        }

        self.totalPedido += oItemPedido.preco;

      });

      self.pedido = self.aplicarDesconto(self.pedido);

      if (parseInt(self.formaPagamento) == 4) {
        self.focoTrocoPara();
      } else {
        localStorage.setItem(self.chaveLocalStorage, JSON.stringify(self.pedido));
      }
    }
  }

  public finalizar() {
    debugger;
    this.espera(true);
    
    this.pedido.subTotal = this.subTotalPedido;
    this.pedido.valorTotal = this.totalPedido + this.pedido.taxaEntrega;
    this.pedido.descontoOuCobranca = this.valorDesconto;

    if (this.pedido.formaPagamento == 4) {
      this.pedido.valorTroco = this.trocoPara - this.pedido.valorTotal;
    }

    this.pedido.status = StatusEnum.Forno;
    this.pedido.clienteId = this.pedido.cliente.id;
    //imprimir via forno
    //imprimir via motoboy
    //gravar Pedido no banco de dados
    this.pedido.usuarioId = this.usuarioServico.usuario.id;
    this.pedidoServico.salvar(this.pedido).subscribe(
      retorno => {
        this.imprimirViaServico();
        // apagar pedido do localstorage
        localStorage.removeItem(this.chaveLocalStorage);

        this.router.navigate(['/identifica-cliente']);
        this.espera(false);
      },
      erro => {
        this.espera(false);
        //this.msg= "Ocorreu um erro ao finalizar o pedido."
      }
    );


  }

  requestUsb() {
    this.usbPrintDriver.requestUsb().subscribe(result => {
      this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
    });
  }

  public espera(ativa: boolean) {
    this.ativar_spinner = ativa;
  }

  public parseFloat(n: string): number {
    return parseFloat(n);
  }


  print(driver: PrintDriver) {
    this.pedido.valorTotal = this.totalPedido + this.pedido.taxaEntrega;

    if (this.pedido.formaPagamento == 4) {
      this.pedido.valorTroco = this.trocoPara - this.pedido.valorTotal;
    }

    this.pedido.clienteId = this.pedido.cliente.id;
    this.pedido.usuarioId = this.usuarioServico.usuario.id;
    this.pedidoServico.imprimirPedido(this.pedido).subscribe(
      impressao => {
        console.log(impressao.command);

        eval(impressao.command);
      },
      error => {
        this.mensagem = error;
      }
    )

  }

  imprimirViaServico() {

    this.pedido.valorTotal = this.totalPedido + this.pedido.taxaEntrega;

    if (this.pedido.formaPagamento == 4) {
      this.pedido.valorTroco = this.trocoPara - this.pedido.valorTotal;
    }

    this.pedido.clienteId = this.pedido.cliente.id;
    this.pedido.usuarioId = this.usuarioServico.usuario.id;

    this.pedidoServico.montarImpressaoPedido(this.pedido).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            console.log(retorno);
            this.pedidoServico.imprimindo = false;
          },
          error => {
            debugger;
            this.pedidoServico.imprimirViaServico(impressao).subscribe(
              retorno => {
                console.log(retorno);
                this.pedidoServico.imprimindo = false;
              },
              error => {
                this.pedidoServico.imprimindo = false;
              }
            );
            this.mensagem = error;
          }
        )
      },
      error => {
        this.mensagem = error;
      }
    )

  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'F2') {
      this.finalizar();
    }
  }
}
