import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { DadosRelatorio } from "../../model/dadosRelatorio";
import { Workbook } from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import { RelatorioVendasSabores } from "../../model/relatorioVendasSabores";
import { List } from "linqts";

@Injectable({
  providedIn: "root"
})
export class RelatorioServico implements OnInit {
  private baseURL: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {

  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  public obterVendasMes(relatorio: DadosRelatorio): Observable<DadosRelatorio[]> {
    return this.http.post<DadosRelatorio[]>(this.baseURL + "api/relatorio/obter-vendas-mes", JSON.stringify(relatorio), { headers: this.headers });
  }

  public obterVendasSabores(relatorio: DadosRelatorio): Observable<RelatorioVendasSabores[]> {
    return this.http.post<RelatorioVendasSabores[]>(this.baseURL + "api/relatorio/obter-vendas-sabores", JSON.stringify(relatorio), { headers: this.headers });
  }

  public obterVendasMesDiaDia(relatorio: DadosRelatorio): Observable<DadosRelatorio[]> {
    return this.http.post<DadosRelatorio[]>(this.baseURL + "api/relatorio/obter-vendas-mes-dia-dia", JSON.stringify(relatorio), { headers: this.headers });
  }

  public obterVendasPlataforma(relatorio: DadosRelatorio): Observable<DadosRelatorio[]> {
    return this.http.post<DadosRelatorio[]>(this.baseURL + "api/relatorio/obter-vendas-plataforma", JSON.stringify(relatorio), { headers: this.headers });
  }

  public gerarExcelSabores(dados) {


    // Excel Title, Header, Data
    const title = 'Vendas por sabores';
    const header = ['Descrição', 'Quantidade', 'Mês Pedido', 'Ano Pedido', 'Período', 'Preço total'];

    const data = [];

    dados.forEach(d => {
      data.push([d.descricao, d.quantidade, d.mesPedido, d.anoPedido, d.periodo, d.precoTotal]);
    });

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sabores');

    // Add Row and formatting
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.addRow([]);

    worksheet.mergeCells('A1:D2');

    // Blank Row
    worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    // Add Data and Conditional Formatting
    data.forEach(d => {
      worksheet.addRow(d);
    }

    );

    worksheet.getColumn(6).numFmt = '"R$ "#,##0.00;[Red]\-"R$ "#,##0.00';

    worksheet.getColumn(2).width = 11;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(3).width = 11;
    worksheet.getColumn(4).width = 11;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    
    const rowCount = worksheet.rowCount;

    worksheet.getRow(rowCount).getCell(1).font = { bold: true };
    worksheet.getRow(rowCount).getCell(3).value = '';
    worksheet.getRow(rowCount).getCell(4).value = '';
    worksheet.getRow(rowCount).getCell(5).font = { bold: true };

    worksheet.getRow(rowCount).getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(3).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(4).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(5).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(6).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };

    worksheet.getRow(rowCount).getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(6).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    worksheet.addRow([]);

    // Footer Row
    const footerRow = worksheet.addRow(['Planilha gerada pelo SGP - Sistema de Gestão de Pedidos.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    // Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'RelatorioSabores.xlsx');
    });

  }

  public gerarExcelVendasDiaDia(dados) {


    // Excel Title, Header, Data
    const title = 'Vendas por sabores';
    const header = ['Descrição', 'Quantidade', 'Dia Pedido', 'Mês Pedido', 'Ano Pedido', 'Período', 'Preço total'];

    const data = [];

    dados.forEach(d => {
      data.push([d.descricao, d.quantidade, d.diaPedido, d.mesPedido, d.anoPedido, d.periodo, d.precoTotal]);
    });

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Vendas');

    // Add Row and formatting
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.addRow([]);

    worksheet.mergeCells('A1:D2');

    // Blank Row
    worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    // Add Data and Conditional Formatting
    data.forEach(d => {
      worksheet.addRow(d);
    }

    );

    worksheet.getColumn(7).numFmt = '"R$ "#,##0.00;[Red]\-"R$ "#,##0.00';

    worksheet.getColumn(2).width = 11;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(3).width = 11;
    worksheet.getColumn(4).width = 11;
    worksheet.getColumn(5).width = 11;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;

    const rowCount = worksheet.rowCount;

    worksheet.getRow(rowCount).getCell(1).font = { bold: true };
    worksheet.getRow(rowCount).getCell(3).value = '';
    worksheet.getRow(rowCount).getCell(4).value = '';
    worksheet.getRow(rowCount).getCell(5).value = '';
    worksheet.getRow(rowCount).getCell(6).font = { bold: true };

    worksheet.getRow(rowCount).getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(3).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(4).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(5).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(6).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(7).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };

    worksheet.getRow(rowCount).getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(6).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(7).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    worksheet.addRow([]);

    // Footer Row
    const footerRow = worksheet.addRow(['Planilha gerada pelo SGP - Sistema de Gestão de Pedidos.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    // Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'RelatorioPedidos.xlsx');
    });

  }

  public obterEntregadoresMes(relatorio: DadosRelatorio): Observable<DadosRelatorio[]> {
    return this.http.post<DadosRelatorio[]>(this.baseURL + "api/relatorio/obter-entregadores-mes", JSON.stringify(relatorio), { headers: this.headers });
  }

  public obterEntregadoresMesDiaDia(relatorio: DadosRelatorio): Observable<DadosRelatorio[]> {
    return this.http.post<DadosRelatorio[]>(this.baseURL + "api/relatorio/obter-entregadores-mes-dia-dia", JSON.stringify(relatorio), { headers: this.headers });
  }

  public gerarExcelEntregadoresDiaDia(dados) {


    // Excel Title, Header, Data
    const title = 'Entregadores';
    const header = ['Entregador', 'Entregas', 'Dia Pedido', 'Mês Pedido', 'Ano Pedido'];

    const data = [];

    dados.forEach(d => {
      data.push([d.descricao, d.quantidade, d.diaPedido, d.mesPedido, d.anoPedido]);
    });

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Entregadores');

    // Add Row and formatting
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.addRow([]);

    worksheet.mergeCells('A1:D2');

    // Blank Row
    worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    // Add Data and Conditional Formatting
    data.forEach(d => {
      worksheet.addRow(d);
    }

    );

    worksheet.getColumn(2).width = 11;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(3).width = 11;
    worksheet.getColumn(4).width = 11;
    worksheet.getColumn(5).width = 11;
    worksheet.getColumn(6).width = 15;

    const rowCount = worksheet.rowCount + 1;

    worksheet.getRow(rowCount).getCell(1).font = { bold: true };
    worksheet.getRow(rowCount).getCell(3).value = '';
    worksheet.getRow(rowCount).getCell(4).value = '';
    worksheet.getRow(rowCount).getCell(5).value = '';
    worksheet.getRow(rowCount).getCell(6).font = { bold: true };

    worksheet.getRow(rowCount).getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(3).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(4).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(5).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };
    worksheet.getRow(rowCount).getCell(6).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C9C9C9' }
    };

    worksheet.getRow(rowCount).getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(2).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(3).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(4).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(5).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getRow(rowCount).getCell(6).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    worksheet.addRow([]);

    // Footer Row
    const footerRow = worksheet.addRow(['Planilha gerada pelo SGP - Sistema de Gestão de Pedidos.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    // Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'RelatorioEntregadores.xlsx');
    });

  }
}
