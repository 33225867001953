import { Component, OnInit } from "@angular/core";
import { EntregadorServico } from "../../servicos/entregador/entregador.servico";
import { Router } from "@angular/router";
import { Entregador } from "../../model/entregador";

@Component({
  selector: "pesquisa-entregador",
  templateUrl: "./pesquisa.entregador.component.html",
  styleUrls: ["./pesquisa.entregador.component.css"]
})

export class PesquisaEntregadorComponent implements OnInit {

  public entregadores: Entregador[];
  public mensagem: string;
  ngOnInit(): void {

  }

  constructor(private entregadorServico: EntregadorServico, private router: Router) {
    this.listaCarregadores();
  }

  listaCarregadores() {
    this.entregadorServico.obterTodosEntregadores()
      .subscribe(
        entregadores => {
          this.entregadores = entregadores;
        },
        err => {
          console.log(err.error);
        });
  }

  editarEntregador(entregador: Entregador) {
    this.router.navigate([`/cadastrar-entregador/${entregador.id}`]);
  }
  
  excluirEntregador(entregador: Entregador) {
    if (confirm(`Deseja realmente excluir o entregador ${entregador.nome}?`))
      this.entregadorServico.excluir(entregador).subscribe(
        ok => {
          this.listaCarregadores();
        },
        erro => {
          this.mensagem = "Ocorreu um erro ao excluir o entregador";
        }
      );
  }

  adicionarEntregador() {
    this.router.navigate(['/cadastrar-entregador']);
  }
}
