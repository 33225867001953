export class Taxa {
  id: number;
  ordem: number;
  nome: string;
  taxaValor: number;
  raio?: number;
  tipo: number;
  polygon: any[];
}

