export class Sabor {
  id: number;
  nome: string;
  descricao: string;
  quantidade: number;
  nomeArquivo: string;
  precoCheio: number;
  tipoSabor: number;
  precoCalculado: number;
}
