import { Component, OnInit } from "@angular/core"
import { Sabor } from "../../model/sabor";
import { SaborServico } from "../../servicos/sabor/sabor.servico";
import { Router } from "@angular/router";

@Component({
  selector: "app-sabor",
  templateUrl: "./cadastro.sabor.component.html",
  styleUrls: ["./cadastro.sabor.component.css"]
})
export class CadastroSaborComponent implements OnInit {
  public sabor: Sabor;
  public arquivoSelecionado: File;
  public ativar_spinner: boolean;
  public mensagem: string;
  public saborExistente: boolean = false;

  constructor(private saborServico: SaborServico, private router: Router) {

  }

  ngOnInit(): void {
    var saborSession = sessionStorage.getItem('saborSession');

    if (saborSession) {
      this.sabor = JSON.parse(saborSession);
    }
    else {
      this.sabor = new Sabor();
    }
  }

  public inputChange(files: FileList) {
    this.arquivoSelecionado = files.item(0);
    this.ativarEspera();
    this.saborServico.enviarArquivo(this.arquivoSelecionado)
      .subscribe(
        nomeArquivo => {
          this.sabor.nomeArquivo = nomeArquivo;
          this.desativarEspera();
        },
        err => {
          console.log(err.error);
          this.desativarEspera();
        }
      );
  }

  public cadastrar() {
    this.ativarEspera();
    this.saborServico.cadastrar(this.sabor)
      .subscribe(
        saborRetorno => {
          console.log(saborRetorno);
          sessionStorage.removeItem('saborSession');
          this.desativarEspera();
          this.router.navigate(['/pesquisar-sabor']);
        },
        err => {
          this.mensagem = err.error;
          this.desativarEspera();
        }
      );
  }

  public verificarSaborExistente() {
    
    this.ativarEspera();
    this.saborServico.obterSabor(this.sabor)
      .subscribe(
        saborRetorno => {
          if (saborRetorno == null) {
            this.saborExistente = false;
          }
          else {
            this.saborExistente = true;
          }

          this.desativarEspera();
        },
        err => {
          this.mensagem = err.error;
          this.desativarEspera();
        }
      );
  }

  public ativarEspera() {
    this.ativar_spinner = true;
  }

  public desativarEspera() {
    this.ativar_spinner = false;
  }
}

