import { ProdutoSabor } from "./produtoSabor";
import { Produto } from "./produto";
import { Sabor } from "./sabor";

export class ItemPedido {
  id: number;
  pedidoId: number;
  produtoId: number;
  saborId: number;
  ordemAgrupamento: number;
  quantidade: number;
  preco: number;
  precoCheio: number;
  observacao: string;
  nomeProduto: string;
  tipoProdutoId: number;
  produto: Produto;
  exibeLinhaSeparacao: boolean;
  quantidadeFracionada: string;
  sabor: Sabor;
  ultimoItem: boolean;
}
