import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Pedido, StatusEnum } from "../../model/pedido";
import { Observable } from "rxjs";
import { Telefone } from "../../model/telefone";
import { DadosImpressao } from "../../model/dadosImpressao";
import { RetornoImpressao } from "../../model/retornoImpressao";
import { RelatorioFechamentoCaixa } from "../../model/relatorioFechamentoCaixa";
import { MotivoCancelamento } from "../../model/motivoCancelamento";
import { Cliente } from "../../model/cliente";
import { DadosLoja } from "../../model/dadosLoja";
import { RegistroPedidoIfood } from "../../model/registroPedidoIfood";
import { PedidoEntregador } from "../../model/pedidoEntregador";
import { PedidoIntegracao } from "../../model/pedidoIntegracao";


@Injectable({
  providedIn: "root"
})
export class PedidoServico implements OnInit {

  private baseURL: string;
  public imprimindo: boolean = false;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {
  }


  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  public salvar(pedido: Pedido): Observable<Pedido> {
    return this.http.post<Pedido>(this.baseURL + "api/pedido/", JSON.stringify(pedido), { headers: this.headers });
  }

  consultaPedidoPorNumero(numeroPedido: number): Observable<Pedido> {
    return this.http.get<Pedido>(this.baseURL + "api/pedido/buscaPedido/" + numeroPedido);
  }

  atualizar(pedido: Pedido): Observable<Pedido> {
    return this.http.post<Pedido>(this.baseURL + 'api/pedido/atualizar/', JSON.stringify(pedido), { headers: this.headers });
  }

  consultarPedidosAbertos(filtroHoje: boolean, filtroAbertos: boolean, filtroIfood: boolean): Observable<Pedido[]> {
    return this.http.get<Pedido[]>(this.baseURL + 'api/pedido/listarPedidosAbertos/' + filtroHoje + '/' + filtroAbertos + '/' + filtroIfood);
  }

  public buscaPedidosPorTelefone(telefone: Telefone, filtroPesquisa: boolean): Observable<Pedido[]> {
    return this.http.post<Pedido[]>(this.baseURL + "api/pedido/buscaPedidosPorTelefone/" + filtroPesquisa, telefone, { headers: this.headers });
  }

  public imprimirPedido(pedido: Pedido): Observable<Retorno> {
    return this.http.post<Retorno>(this.baseURL + "api/pedido/imprimirPedido", JSON.stringify(pedido), { headers: this.headers });
  }

  montarImpressaoPedido(pedido: Pedido): Observable<DadosImpressao> {
    return this.http.post<DadosImpressao>(this.baseURL + "api/pedido/imprimirPedidoServico", JSON.stringify(pedido), { headers: this.headers });
  }

  montarImpressaoFechamentoComanda(pedido: Pedido): Observable<DadosImpressao> {
    return this.http.post<DadosImpressao>(this.baseURL + "api/pedido/imprimirFechamentoComanda", JSON.stringify(pedido), { headers: this.headers });
  }

  imprimirViaServico(dadosImpressao: DadosImpressao): Observable<RetornoImpressao> {
    return this.http.post<RetornoImpressao>("http://localhost:9000/api/Impressao/Imprimir", JSON.stringify(dadosImpressao), { headers: this.headers });
  }

  consultarRelatorioDiario(): Observable<RelatorioFechamentoCaixa> {
    return this.http.get<RelatorioFechamentoCaixa>(this.baseURL + "api/pedido/consultar-relatorio-diario/");
  }

  public imprimirFechamentoCaixa(relatorioManual: RelatorioFechamentoCaixa): Observable<DadosImpressao> {
    return this.http.post<DadosImpressao>(this.baseURL + "api/pedido/imprimir-fechamento-caixa", JSON.stringify(relatorioManual), { headers: this.headers });
  }

  public inserirMotivoCancelamento(motivo: MotivoCancelamento): Observable<MotivoCancelamento> {
    return this.http.post<MotivoCancelamento>(this.baseURL + 'api/pedido/salvar-motivo-cancelamento/', JSON.stringify(motivo), { headers: this.headers });
  }

  verificarNovosPedidosIntegracao(): Observable<PedidoIntegracao> {
    return this.http.get<PedidoIntegracao>(this.baseURL + "api/pedido/verificar-novos-pedidos-integracao/");
  }

  aceitarPedidoIntegracao(): Observable<Pedido> {
    return this.http.get<Pedido>(this.baseURL + "api/pedido/aceitar-pedido-integracao/");
  }

  buscarUltimoPedido(clienteId: number): Observable<Pedido> {
    return this.http.get<Pedido>(this.baseURL + "api/pedido/obter-ultimo-pedido-cliente/" + clienteId);
  }

  obterStatusLojaIfood(): Observable<DadosLoja> {
    return this.http.get<DadosLoja>(this.baseURL + "api/pedido/obter-status-loja-ifood/");
  }

  consultaPedidosPorNome(cliente: Cliente, filtroPesquisa: boolean): Observable<Pedido[]> {
    return this.http.post<Pedido[]>(this.baseURL + "api/pedido/buscaPedidosPorNome/" + filtroPesquisa, cliente, { headers: this.headers });
  }

  atualizarStatusIfood(registroPedidoIfood: RegistroPedidoIfood, url: string) {
    return this.http.post<RetornoImpressao>(url + "/api/pedido/atualizar", JSON.stringify(registroPedidoIfood), { headers: this.headers });
  }

  obterServidorIfood(): Observable<string> {
    return this.http.get<string>(this.baseURL + "api/pedido/obter-servidor-ifood/");
  }

  atualizarEntregadoresEmlote(lote: PedidoEntregador[]) {
    return this.http.post<RetornoImpressao>(this.baseURL + "/api/pedido/atualizar-entregadores-lote", JSON.stringify(lote), { headers: this.headers });
  }
}

export class Retorno {
  command: string
}
