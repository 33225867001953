import { Usuario } from "./usuario";
import { Cliente } from "./cliente";
import { ItemPedido } from "./itemPedido";
import { Distance } from "./distanceMatrix";
import { Entregador } from "./entregador";
import { PagamentoPedido } from "./pagamentoPedido";
import { MotivoCancelamento } from "./motivoCancelamento";

export enum FormaPagamentoEnum {
  CartaoCredito = 1,
  CartaoDebito = 2,
  ValeRefeicao = 3,
  Dinheiro = 4,
  Pix = 5
}

export enum StatusEnum {
  NaoDefinido = 0,
  EmAndamento = 1,
  Forno = 2,
  SaiuParaEntrega = 3,
  Entregue = 4,
  Cancelado = 5,
  Recusado = 6,
  Recebido = 7,
  Retirar = 8
}

export enum OrigemPedidoEnum {
  NaoDefinido = 0,
  Loja = 1,
  Ifood = 2,
  AnotaAi = 3
}

export class Pedido {
  id: number;
  numeroPedido: number;
  dataPedido: Date;
  usuarioId: number;
  usuario: Usuario;
  itensPedido: ItemPedido[];
  clienteId: number;
  cliente: Cliente;
  distancia: Distance;
  formaPagamento: FormaPagamentoEnum;
  taxaEntrega: number;
  balcao: boolean;
  valorTotal: number;
  valorTroco: number;
  valorPago: number;
  status: StatusEnum;
  entregadorId: number;
  entregador: Entregador;
  numeroPedidoDia: number;
  subTotal: number;
  descontoOuCobranca: number;
  observacaoGeral: string;
  valorCaixinha: number;
  valorCortesia: number;
  origemPedido: number;
  bandeira: string;
  cupomDesconto: string;
  notaFiscal: boolean;
  ifoodId: string;
  pagamentoPedido: PagamentoPedido;
  dataDespacho: Date;
  motivoCancelamento: MotivoCancelamento;
  observacaoGeralCliente: string;

  public calculaValorTotal(): number {
    var total = this.balcao ? 0 : this.taxaEntrega;
    this.itensPedido.forEach(function (ip) {
      total += ip.preco;
    });
    return total;
  }
}
