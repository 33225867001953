/// <reference types="@types/googlemaps" />

export class MyPolygon {
  polygon: google.maps.Polygon;
  circle: google.maps.Circle;
  id: number;
  nome: string;
  ordem: number;
  taxaValor: number;
  tipo: number;
  raio: number;

  constructor(params: MyPolygon = {} as MyPolygon) {
    if (this.polygon == undefined)
      this.polygon = new google.maps.Polygon();

    if (this.tipo == undefined)
      this.tipo = 2;

    if (this.tipo == 2)
      this.circle = new google.maps.Circle();

    Object.assign(this, params);
  }
}
