import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Receita } from "../../model/receita";
import { Sabor } from "../../model/sabor";

@Injectable({
  providedIn: "root"
})
export class ReceitaServico implements OnInit {
  private baseURL: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {
  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  public cadastrar(receita: Receita): Observable<Receita> {
    return this.http.post<Receita>(this.baseURL + "api/receita", JSON.stringify(receita), { headers: this.headers });
  }

  public salvar(receita: Receita): Observable<Receita> {
    return this.http.post<Receita>(this.baseURL + "api/receita/salvar", JSON.stringify(receita), { headers: this.headers });
  }

  public excluir(receita: Receita): Observable<Receita[]> {
    return this.http.post<Receita[]>(this.baseURL + "api/receita/excluir", JSON.stringify(receita), { headers: this.headers });
  }

  public obterTodasReceitas(): Observable<Receita[]> {
    return this.http.get<Receita[]>(this.baseURL + "api/receita");
  }

  public obterReceitasPorSabor(saborId: number): Observable<Receita[]> {
    return this.http.get<Receita[]>(this.baseURL + "api/receita/obter-por-sabor/" + saborId);
  }

  public obterReceitasPorMercadoria(mercadoriaId: number): Observable<Receita[]> {
    return this.http.get<Receita[]>(this.baseURL + "api/receita/obter-por-mercadoria/" + mercadoriaId);
  }

  public cadastrarTodas(receitas: Receita[]): Observable<Receita[]> {
    return this.http.post<Receita[]>(this.baseURL + "api/receita/cadastrar-todas", JSON.stringify(receitas), { headers: this.headers });
  }
}
