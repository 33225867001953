export enum TipoProdutoEnum {
  NaoDefinido = 0,
  PizzaSalgada = 1,
  PizzaDoceGrande = 2,
  PizzaDoceBroto = 3,
  Bebida = 4,
  PizzaSalgadaBroto = 5,
  Beirute = 6,
  Pastel = 7,
  Porcao = 8,
  Refeicao = 9,
  BeiruteBroto = 10,
  Adicionais = 11
}

export class ProdutoSabor {
  saborId: number;
  produtoId: number;
  nome: string;
  precoCheio: number;
  tipoProduto: TipoProdutoEnum;
  descricao: string;
  nomePesquisa: string;
}
