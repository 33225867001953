import { Component, OnInit } from "@angular/core";
import { Mercadoria } from "../../model/mercadoria";
import { MercadoriaServico } from "../../servicos/mercadoria/mercadoria.servico";
import { Router } from "@angular/router";

@Component({
  selector: "pesquisa-mercadoria",
  templateUrl: "./pesquisa.mercadoria.component.html",
  styleUrls: ["./pesquisa.mercadoria.component.css"]
})

export class PesquisaMercadoriaComponent implements OnInit {

  public mercadorias: Mercadoria[];
  pag: Number = 1;
  contador: Number = 5;
  searchText;

  ngOnInit(): void {

  }

  constructor(private mercadoriaServico: MercadoriaServico, private router: Router) {
    this.mercadoriaServico.obterTodasMercadorias()
      .subscribe(
        mercadorias => {
          this.mercadorias = mercadorias;
        },
        err => {
          console.log(err.error);
        }
      )
  }

  public adicionarMercadoria() {
    sessionStorage.setItem('mercadoriaSession', '');
    this.router.navigate(['/cadastrar-mercadoria']);
  }

  public excluirMercadoria(mercadoria: Mercadoria) {
    var retorno = confirm("Deseja realmente excluir a mercadoria selecionada?");
    if (retorno == true) {
      this.mercadoriaServico.excluir(mercadoria).subscribe(
        mercadorias => {
          this.mercadorias = mercadorias;
        },
        err => {
          console.log(err.error);
        }
      );
    }
  }

  public editarMercadoria(mercadoria: Mercadoria) {
    sessionStorage.setItem('mercadoriaSession', JSON.stringify(mercadoria));
    this.router.navigate(['/cadastrar-mercadoria']);
  }
}
