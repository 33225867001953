import { Mercadoria } from "./mercadoria";
import { Sabor } from "./sabor";

export class Receita {
  id: number;
  saborId: number;
  sabor: Sabor;
  mercadoriaId: number;
  mercadoria: Mercadoria;
  quantidade: number;
}
