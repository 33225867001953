import { Component, OnInit, NgZone, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Cliente } from "../../model/cliente";
import { ClienteServico } from "../../servicos/cliente/cliente.servico";
import { Endereco } from "../../model/endereco";
import { Pedido, StatusEnum } from "../../model/pedido";
import "../../extensions/string.extension";
import { PedidoConstants } from "../../constants/pedido.constants";
import { Telefone } from "../../model/telefone";
import { EnderecoViaCep } from "../../model/enderecoViaCep";
import { DistanceMatrixServico } from "../../servicos/distance-matrix/distance-matrix.servico";
import { formatDate, DatePipe } from "@angular/common";
import { List } from 'linqts';
import { TaxaServico } from "../../servicos/taxas/taxa.servico";
import { Taxa } from "../../model/taxa";
import { InjecaoSaldo } from "../../model/injecaoSaldo";
import { UsuarioServico } from "../../servicos/usuario/usuario.servico";
import { PedidoServico } from "../../servicos/pedido/pedido.servico";
import { PagamentoPedido } from "../../model/pagamentoPedido";
import { ConfiguracaoSistemaServico } from "../../servicos/configuracaoSistema/configuracaoSistema.servico";


//variavel para utilizar jquery
declare var $: any;

@Component({
  selector: "app-identifica-cliente",
  templateUrl: "./identifica-cliente.component.html",
  styleUrls: ["./identifica-cliente.component.css"]
})

export class IdentificaClienteComponent implements OnInit {

  public ativar_spinner: boolean;
  public clienteExiste: boolean = false;
  public clienteAtualizar: boolean = false;
  public cliente: Cliente;
  public telefone: Telefone;
  public endereco: Endereco;
  public pedido: Pedido;
  public mensagem: string;
  public distancia: any;
  private numeroPedido: number = 0;
  public injetarSaldo: number = 0;
  public buscouUltimoPedido: boolean = false;
  public permiteBalcao: boolean = false;


  constructor(private router: Router, private clienteService: ClienteServico, private route: ActivatedRoute, private distanceMatrixService: DistanceMatrixServico, private datePipe: DatePipe, private taxaServico: TaxaServico, private zone: NgZone, private usuarioServico: UsuarioServico, private pedidoServico: PedidoServico, private configuracaoSistemaServico: ConfiguracaoSistemaServico) {
    this.iniciarPedido();
    this.validaCliente(false);
  }

  ngOnInit(): void {
    var pedidoRecuperado = this.recuperaPedidoIniciado();

    if (!pedidoRecuperado) {
      this.telefone = new Telefone();
      this.endereco = new Endereco();
      this.cliente = new Cliente();
      this.pedido = new Pedido();

      $(() => {
        $('#txtClienteTelefone').focus();
        this.telefone.ddd = 11;
      });
    }

    this.configuracaoSistemaServico.obterTodos()
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            data.forEach(config => {
              if (config.descricao == 'PermiteBalcao') {
                if (config.valor == 'true') {
                  this.permiteBalcao = true;
                }
                else {
                  this.permiteBalcao = false;
                }
              }
            });
          }
        },
        err => {
          console.log(err.error);
        }
      );
  }

  private recuperaPedidoIniciado(): boolean {
    var self = this;
    var existeCliente = false;
    //gera o número do Pedido por default
    self.numeroPedido = parseInt(this.datePipe.transform(new Date(), "yyyyMMddHHmmss", "-0300"));


    //recupera o numeroPedido se vier pela url e preenche o Pedido
    this.route.params.subscribe(
      params => {
        if (params.numeroPedido !== undefined) {
          self.numeroPedido = parseInt(params.numeroPedido);

          var chaveLocalStorage = PedidoConstants.CHAVE_PEDIDO_LOCAL_STORAGE.format({ numeroPedido: this.numeroPedido });
          var p = <Pedido>JSON.parse(localStorage.getItem(chaveLocalStorage));

          if (p) {
            self.pedido = p;
            self.cliente = this.deepClone(p.cliente);
            self.endereco = this.deepClone(new List<Endereco>(p.cliente.enderecos).FirstOrDefault(e => e.selecionado == true));
            self.telefone = this.deepClone(new List<Telefone>(p.cliente.telefones).FirstOrDefault());
            self.distancia = p.distancia;
            self.validaCliente(true);

            existeCliente = true;
          }
        }
      },
      erro => {
      });

    return existeCliente;
  }

  private validaCliente(existe: boolean) {
    this.clienteExiste = existe;
  }

  private espera(ativa: boolean) {
    this.ativar_spinner = ativa;
  }

  private deepClone(o: any): any {
    if (o == null)
      return null;

    return JSON.parse(JSON.stringify(o));
  }

  private iniciarPedido() {

    if (!this.buscouUltimoPedido || (this.buscouUltimoPedido && !this.pedido)) {
      if (!this.pedido)
        this.pedido = new Pedido();

      if (!this.pedido.cliente)
        this.pedido.cliente = new Cliente();

      if (!this.pedido.cliente.enderecos)
        this.pedido.cliente.enderecos = [];

      if (!this.pedido.cliente.telefones)
        this.pedido.cliente.telefones = [];

      if (this.cliente)
        this.pedido.cliente = this.cliente;

      if (!this.pedido.pagamentoPedido)
        this.pedido.pagamentoPedido = new PagamentoPedido();

      if (this.telefone != null) {
        if (!this.ehObjetoVazio(this.telefone) && !this.ContemObjeto(this.telefone, this.pedido.cliente.telefones)) {
          //Se implementar multi telefones um dia, descomentar essa parte
          //new List<Telefone>(this.pedido.cliente.telefones);
          //this.pedido.cliente.telefones = new List<Telefone>(this.pedido.cliente.telefones).Where(e => e.ddd != this.telefone.ddd && e.numero != this.telefone.numero).Distinct().ToArray();
          this.pedido.cliente.telefones = [];
          this.pedido.cliente.telefones.push(this.deepClone(this.telefone));
        }
      }

      if (this.endereco != null) {
        if (!this.ehObjetoVazio(this.endereco) && !this.ContemObjeto(this.endereco, this.pedido.cliente.enderecos)) {

          //Se implementar multi endereços um dia, descomentar essa parte
          //new List<Endereco>(this.pedido.cliente.enderecos).ForEach(e => { e.selecionado = false });
          //this.pedido.cliente.enderecos = new List<Endereco>(this.pedido.cliente.enderecos).Where(e => e.cep != this.endereco.cep).Distinct().ToArray();
          this.pedido.cliente.enderecos = [];
          this.pedido.cliente.enderecos.push(this.deepClone(this.endereco));
        }
      }

      this.pedido.numeroPedido = this.numeroPedido;
      this.pedido.status = StatusEnum.EmAndamento;
    }
  }

  public buscaClientePorTelefone() {
    var self = this;
    
    if (self.telefone.numero == 0 || self.telefone.numero.toString().length < 8) {
      self.espera(false);
      self.validaCliente(false);
      self.clienteAtualizar = false;
      self.mensagem = "Telefone incorreto!";

      return;
    }
    self.mensagem = "";
    self.espera(true);

    this.clienteService.buscaClientePorTelefone(self.telefone)
      .subscribe(
        clienteRetorno => {
          self.cliente = clienteRetorno;
          self.endereco = this.deepClone(new List<Endereco>(clienteRetorno.enderecos).FirstOrDefault());
          self.iniciarPedido();
          self.espera(false);
          self.validaCliente(true);
          self.clienteAtualizar = false;
        },
        err => {
          //self.cliente = null;
          self.iniciarPedido();
          self.espera(false);
          self.validaCliente(false);
          self.mensagem = err.error;
          $(() => {
            $('#txtClienteCep').focus();
          });
        }
      );
  }

  public consultaCep() {
    this.espera(true);
    
    if (this.endereco.cep == null || this.endereco.cep.length == 0) {
      this.mensagem = "Cep não preenchido";
      this.espera(false);
      this.validaCliente(false);
      this.endereco = new Endereco();
      return;
    }

    //if (this.validaCliente) {
    if (this.cliente != null) {
      if (this.cliente.enderecos.length > 0) {
        var self = this;
        var end = new List<Endereco>(this.cliente.enderecos).FirstOrDefault(e => e.cep == self.endereco.cep);

        if (end != undefined) {
          end.selecionado = true;
          this.endereco = end;
          this.iniciarPedido();
          this.espera(false);
          this.clienteAtualizar = false;
          return;
        }
      }
    }

    this.clienteService.consultaCep(this.endereco.cep).subscribe(
      retornoCep => {
        if (retornoCep != null && !retornoCep.erro) {
          this.convertToEndereco(retornoCep);
          this.endereco.selecionado = true;
          this.iniciarPedido();
          this.espera(false);
          this.clienteAtualizar = true;

          this.mensagem = null;
        }
        else {
          this.espera(false);
          this.mensagem = "Endereço não foi encontrado.";
        }
      },
      err => {
        this.espera(false);
        this.mensagem = err.error;
      });

  }

  private salvarLocalStorage(callback) {
    debugger;
    var chaveLocalStorage = PedidoConstants.CHAVE_PEDIDO_LOCAL_STORAGE.format({ numeroPedido: this.numeroPedido });
    var end = new List<Endereco>(this.cliente.enderecos).FirstOrDefault(e => e.cep == this.endereco.cep);
    var self = this;
    this.espera(true);
    //só consulta a distancia se ainda não foi consultada
    if ((end.lat || 0) == 0 && (end.lng || 0) == 0) {

      var enderecoDestino = `${end.logradouro} ${end.numero} ${end.bairro} ${end.cidade} ${end.uf}`;

      var geoCoder = new google.maps.Geocoder();
      var latlng: google.maps.LatLng;
      geoCoder.geocode({ address: enderecoDestino }, (results, status) => {

        if (status == "OK") {
          latlng = results[0].geometry.location;

          end.lat = latlng.lat();
          end.lng = latlng.lng();

          //TODO: Se tiver mais do que um endereço, rever a lógica
          this.clienteService.salvarCliente(this.cliente).subscribe(
            clienteRetorno => {
              self.cliente = clienteRetorno;
              self.salvarLocalStorage(function () {
                self.espera(false);
                self.validaCliente(true);
                self.router.navigate([`/faz-pedido/${self.numeroPedido}`]);
              });
            },
            err => {
              this.mensagem = err.error;
              this.espera(false);
              this.validaCliente(false);
            });
        }
      });

    }
    else {
      this.taxaServico.consultaTaxaBanco(end.lat, end.lng).subscribe(
        taxa => {

          if (taxa == null || taxa == undefined || (taxa.id || 0) == 0) {
            //self.mensagem = "Este endereço não está em nossa área de cobertura";
            //this.espera(false);
            //return;

            taxa = new Taxa();
            taxa.taxaValor = 0;

            if (this.permiteBalcao) {
              this.pedido.balcao = true;
              this.pedido.cliente.somenteTaxaBalcao = true;
            }
            else {
              this.pedido.balcao = false;
              this.pedido.cliente.somenteTaxaBalcao = false;
            }
          }
          //else {
          self.mensagem = null;
          if (typeof callback === "function") {

            self.pedido.taxaEntrega = taxa.taxaValor;
            end.selecionado = true;
            self.pedido.cliente.pedidos = [];
            localStorage.setItem(chaveLocalStorage, JSON.stringify(self.pedido));

            self.zone.run(() => {
              this.espera(false);
              callback();
            });
          }
          //}
        });
    }
  }

  public convertToEndereco(enderecoViaCep: EnderecoViaCep): void {

    //var e = new Endereco();
    this.endereco.bairro = enderecoViaCep.bairro;
    this.endereco.cep = this.endereco.cep;
    this.endereco.cidade = enderecoViaCep.localidade;
    this.endereco.complemento = enderecoViaCep.complemento;
    this.endereco.logradouro = enderecoViaCep.logradouro;
    this.endereco.uf = enderecoViaCep.uf;
    this.endereco.distancia = 0;
  }

  public continuar() {
    this.clienteAtualizar = true;
    var self = this;
    self.clienteAtualizar = true;
    this.iniciarPedido();
    this.salvarLocalStorage(function () {
      if (self.clienteAtualizar) {
        self.clienteService.salvarCliente(self.cliente).subscribe(ok => {
          self.router.navigate([`/faz-pedido/${self.numeroPedido}`]);
        });
      } else {
        self.router.navigate([`/faz-pedido/${self.numeroPedido}`]);
      }
    });
  }


  public cadastrarContinuar() {
    var self = this;
    this.espera(true);
    //this.cliente.ativo = true;
    if (this.cliente.enderecos != null && this.cliente.enderecos.length > 0) {
      this.cliente.enderecos.forEach((endereco, i) => {
        if (endereco.cep == self.endereco.cep) {
          endereco.numero = self.endereco.numero;
          endereco.complemento = self.endereco.complemento;
          endereco.pontoReferencia = self.endereco.pontoReferencia;
        }

        if (endereco.lat == null || endereco.lng == null) {
          var enderecoDestino = `${endereco.logradouro} ${endereco.numero} ${endereco.bairro} ${endereco.cidade} ${endereco.uf}`;

          var geoCoder = new google.maps.Geocoder();
          var latlng: google.maps.LatLng;
          geoCoder.geocode({ address: enderecoDestino }, (results, status) => {

            if (status == "OK") {
              latlng = results[0].geometry.location;
              endereco.lat = latlng.lat();
              endereco.lng = latlng.lng();

              //TODO: Se tiver mais do que um endereço, rever a lógica
              this.clienteService.salvarCliente(this.cliente).subscribe(
                clienteRetorno => {
                  self.cliente = clienteRetorno;
                  this.endereco = clienteRetorno.enderecos[0];
                  self.iniciarPedido();
                  self.salvarLocalStorage(function () {
                    self.espera(false);
                    self.validaCliente(true);
                    self.router.navigate([`/faz-pedido/${self.numeroPedido}`]);
                  });
                },
                err => {
                  this.mensagem = err.error;
                  this.espera(false);
                  this.validaCliente(false);
                });
            }
          });
        }
        else {
          this.clienteService.salvarCliente(this.cliente).subscribe(
            clienteRetorno => {
              self.cliente = clienteRetorno;
              this.endereco = clienteRetorno.enderecos[0];
              self.iniciarPedido();
              self.salvarLocalStorage(function () {
                self.espera(false);
                self.validaCliente(true);
                self.router.navigate([`/faz-pedido/${self.numeroPedido}`]);
              });
            },
            err => {
              this.mensagem = err.error;
              this.espera(false);
              this.validaCliente(false);
            });
        }
      });
    }
    else {
      this.espera(false);
    }

  }

  public ehObjetoVazio(obj) {
    return !Object.keys(obj).length;
  }

  public ContemObjeto(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  }

  public atualizarSaldo(chave: number) {
    var injecaoSaldo = new InjecaoSaldo();
    injecaoSaldo.clienteId = this.cliente.id;
    injecaoSaldo.usuarioId = this.usuarioServico.usuario.id;
    injecaoSaldo.data = new Date();

    if (chave == 1) {
      injecaoSaldo.valor = this.injetarSaldo;
    }
    else {
      injecaoSaldo.valor = this.injetarSaldo * -1;
    }

    this.espera(true);

    this.clienteService.injetarSaldo(injecaoSaldo).subscribe(
      retornoInjecao => {
        this.cliente.saldo += injecaoSaldo.valor;
        this.espera(false);
      },
      err => {
        this.espera(false);
        this.mensagem = err.error;
      });
  }

  private buscarUltimoPedido() {
    this.espera(true);
    this.buscouUltimoPedido = true;

    this.pedidoServico.buscarUltimoPedido(this.pedido.cliente.id).subscribe(
      retorno => {
        if (retorno != null) {
          retorno.numeroPedido = this.numeroPedido;
          retorno.numeroPedidoDia = 0;
          retorno.id = 0;

          if (retorno.cliente.enderecos != null && retorno.cliente.enderecos.length > 0)
            retorno.cliente.enderecos[0].selecionado = true;

          retorno.itensPedido.forEach((itemPedido, i) => {
            itemPedido.tipoProdutoId = itemPedido.produto.tipoProdutoId;


            if (itemPedido.tipoProdutoId != 4) {
              itemPedido.precoCheio = itemPedido.sabor.precoCheio;
              itemPedido.nomeProduto = itemPedido.sabor.nome;
              itemPedido.quantidadeFracionada = itemPedido.quantidade.toString();
            }
          });
        }
        else {
          alert('Cliente nunca fez pedido.');
        }

        this.pedido = retorno;
        this.espera(false);
      },
      erro => {
        this.espera(false);
        //this.msg= "Ocorreu um erro ao finalizar o pedido."
      }
    );
  }

  public apagarCliente() {
    if (confirm(`Deseja realmente apagar o cadastro do cliente de telefone (${this.telefone.ddd}) ${this.telefone.numero}?`)) {
      this.espera(true);
    }
    else {
      this.espera(false);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'F2') {
      if (this.clienteExiste) {
        this.continuar();
      }
      else {
        this.cadastrarContinuar()
      }
    }
  }
}

