export class RelatorioFechamentoCaixa {
  qtdePedidosEntrega: number;
  qtdePedidosBalcao: number;
  dataRelatorio: Date;
  qtdePedidosAbertos: number;
  totalPagoCartao: number;
  totalPagoDinheiro: number;
  totalNaoPago: number;
  totalPedidos: number;
  qtdeMassas: number;
  fundoDeCaixa: number;
  usuarioId: number;
  qtdePizzasSalgadas: number;
  qtdePizzasDoces: number;
  qtdePizzasBalcao: number;
  qtdePizzasEntrega: number;
  totalCortesia: number;
  totalPagoPix: number;
}
