import { Component, OnInit } from '@angular/core';
import { PedidoServico } from './servicos/pedido/pedido.servico';
import { Pedido } from './model/pedido';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfiguracaoSistemaServico } from './servicos/configuracaoSistema/configuracaoSistema.servico';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  public origemPedido: number = 0;
  public ativar_spinner: boolean = false;
  public possuiPedido: boolean = false;
  currentRoute: string;

  constructor(private pedidoServico: PedidoServico, private router: Router, private route: ActivatedRoute, private configuracaoSistemaServico: ConfiguracaoSistemaServico) {
    router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }

    });
  }

  ngOnInit(): void {
    this.configuracaoSistemaServico.obterTodos()
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            data.forEach(config => {
              if (config.descricao == 'PermiteIntegracao' && config.valor == 'true') {
                this.iniciarVerificacaoIntegracao();
              }
            });
          }
        },
        err => {
          console.log(err.error);
        }
      );
    
  }

  async iniciarVerificacaoIntegracao() {
    let timerId = setInterval(() => {
      this.pesquisarPedidoIntegracao()
    }, 30000);
  }

  pesquisarPedidoIntegracao() {
    //if (this.currentRoute == "/consulta-entrega-pedido" || this.currentRoute == "/") {
    if (this.currentRoute.indexOf("/faz-pedido/") == -1 && this.currentRoute.indexOf("/-relatorio/") == -1) {
      this.pedidoServico.verificarNovosPedidosIntegracao().subscribe(
        retorno => {
          if (retorno.possuiPedido) {
            this.origemPedido = retorno.origemPedido;
            this.possuiPedido = true;
            $(() => {
              $('.toast').toast('show');
              this.playAudio();
            });
          }
          else {
            this.possuiPedido = false;
            $(() => {
              $('.toast').toast('hide');
            });
          }
        },
        erro => {
          console.log(erro);
          //this.msg= "Ocorreu um erro ao finalizar o pedido."
        }
      );
    }
  }

  public aceitarPedidoIntegracao() {
    this.ativar_spinner = true;

    this.pedidoServico.aceitarPedidoIntegracao().subscribe(
      pedido => {
        this.ativar_spinner = false;
        if (pedido != null) {
          this.imprimirPedido(pedido);
        }
        else {
          alert('Pedido já aceito por outro atendente.');
        }

        this.possuiPedido = false;

        $(() => {
          $('.toast').toast('hide');
        });
      },
      erro => {
        console.log(erro);
        this.ativar_spinner = false;
        //this.msg= "Ocorreu um erro ao finalizar o pedido."
      }
    );
  }

  async imprimirPedido(pedido: Pedido) {
    this.ativar_spinner = true;

    while (this.pedidoServico.imprimindo) {
      await this.sleep(1000);
    }

    this.pedidoServico.imprimindo = true;

    this.pedidoServico.montarImpressaoPedido(pedido).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            console.log(retorno);
            this.ativar_spinner = false;
            this.pedidoServico.imprimindo = false;
          },
          error => {
            console.log(error);
            this.ativar_spinner = false;
            this.pedidoServico.imprimindo = false;
          }
        )
      },
      error => {
        console.log(error);
        this.ativar_spinner = false;
      }
    )
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  playAudio() {
    let audio = new Audio();
    if (this.origemPedido == 2)
      audio.src = "../assets/sound/ifood.mp3";
    else
      audio.src = "../assets/sound/whatsapp.mp3";

    audio.load();
    audio.play();
  }

}
