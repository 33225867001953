import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Produto } from "../../model/produto";
import { TipoProduto } from "../../model/tipoProduto";
import { ProdutoSabor } from "../../model/produtoSabor";

@Injectable({
  providedIn: "root"
})
export class ProdutoServico implements OnInit {

  private baseURL: string;
  public produtos: Produto[];
  public tiposProduto: TipoProduto[];

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {
    this.produtos = [];
    this.tiposProduto = [];
  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  public obterTodosTiposProduto(): Observable<TipoProduto[]> {
    return this.http.get<TipoProduto[]>(this.baseURL + "api/tipoproduto/obter-todos");
  }

  public obterTodosProdutos(): Observable<Produto[]> {
    return this.http.get<Produto[]>(this.baseURL + "api/produto");
  }

  public cadastrar(produto: Produto): Observable<Produto> {
    return this.http.post<Produto>(this.baseURL + "api/produto", JSON.stringify(produto), { headers: this.headers });
  }

  public salvar(produto: Produto): Observable<Produto> {
    return this.http.post<Produto>(this.baseURL + "api/produto/salvar", JSON.stringify(produto), { headers: this.headers });
  }

  public excluir(produto: Produto): Observable<Produto[]> {
    return this.http.post<Produto[]>(this.baseURL + "api/produto/excluir", JSON.stringify(produto), { headers: this.headers });
  }

  public obterTodosSabores(): Observable<Produto[]> {
    return this.http.get<Produto[]>(this.baseURL + "api/produto");
  }

  public obterSabor(produto: Produto): Observable<Produto> {
    return this.http.get<Produto>(this.baseURL + "api/produto/obterPorId" + produto.id);
  }

  public enviarArquivo(arquivoSelecionado: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append("arquivoEnviado", arquivoSelecionado, arquivoSelecionado.name);
    return this.http.post<string>(this.baseURL + "api/produto/enviarArquivo", formData);
  }

  public obterTodosProdutosSabores(): Observable<ProdutoSabor[]> {
    return this.http.get<ProdutoSabor[]>(this.baseURL + "api/produto/obter-todos-produtos-sabores");
  }

}
