import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router";
import { RegistroFinanceiro } from "../../model/registroFinanceiro";
import { RegistroFinanceiroServico } from "../../servicos/financeiro/registro-financeiro.servico";
import { UsuarioServico } from "../../servicos/usuario/usuario.servico";
import { MovimentacaoFinanceira } from "../../model/movimentacaoFinanceira";
import { Entregador, ContratoEnum } from "../../model/entregador";
import { List } from "linqts";
import { EntregadorServico } from "../../servicos/entregador/entregador.servico";
import { PedidoServico } from "../../servicos/pedido/pedido.servico";
import { DadosImpressao } from "../../model/dadosImpressao";
import { RelatorioFechamentoCaixa } from "../../model/relatorioFechamentoCaixa";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import { DatePipe } from "@angular/common";
import { DadosRelatorioEntregador } from "../../model/dadosRelatorioEntregador";

//variavel para utilizar jquery
declare var $: any;
declare var require: any;

loadCldr(
  require("cldr-data/main/pt/numbers.json"),
  require("cldr-data/main/pt/ca-gregorian.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/pt/timeZoneNames.json"),
  require('cldr-data/supplemental/weekdata.json') // To load the culture based first day of week
);

@Component({
  selector: "app-registro-financeiro",
  templateUrl: "./registro-financeiro.component.html",
  styleUrls: ["./registro-financeiro.component.css"]
})
export class RegistroFinanceiroComponent implements OnInit {

  public ativar_spinner: boolean;
  public registroFinanceiro: RegistroFinanceiro = new RegistroFinanceiro();
  public movimentacaoFinanceira: MovimentacaoFinanceira = new MovimentacaoFinanceira();
  public mensagem: string;
  public adicionarMovimentacao: boolean;
  public listaEntregadoresAtivos: Entregador[] = [];
  public ContratoEnum = ContratoEnum;
  public entregadorSelecionadoId: number = 0;
  public relatorioFechamentoCaixa: RelatorioFechamentoCaixa = new RelatorioFechamentoCaixa();
  public relatorioFechamentoCaixaManual: RelatorioFechamentoCaixa = new RelatorioFechamentoCaixa();
  public qtdeMassaParaAdicionar: number = 0;
  public permiteReimpressaoCaixa: boolean = false;
  public aproveitarSaldo: boolean = false;
  public minDate: Date = new Date("01/01/2021");
  public maxDate: Date = new Date("12/31/2099");
  public dateValue: Date = new Date();
  public dataSelecionada: Date = new Date();

  constructor(private router: Router, private registroFinanceiroServico: RegistroFinanceiroServico,
    private usuarioServico: UsuarioServico, private entregadorServico: EntregadorServico,
    private pedidoServico: PedidoServico, public datepipe: DatePipe) {

  }

  ngOnInit(): void {
    $(() => {
      $(document).ready(function () {

      })
    });

    this.obterRegistroAtual();

    this.updateTooltip();
  }

  public updateTooltip() {

    //jquery
    $(() => {
      $('[data-toggle=tooltip]').tooltip('dispose');
      $('[data-toggle=tooltip]').tooltip({ container: 'body' });
    });

  }

  private obterRegistroAtual() {
    this.espera(true);

    this.registroFinanceiroServico.obterRegistroAtual()
      .subscribe(
        retorno => {
          this.registroFinanceiro = retorno;

          if (this.registroFinanceiro == null) {
            this.registroFinanceiro = new RegistroFinanceiro();
            this.registroFinanceiro.saldoAtual = 0;
            this.registroFinanceiro.statusRegistro = 1;
            this.registroFinanceiro.data = new Date();
          }
          else {
            if (this.registroFinanceiro.statusRegistro == 1) {
              this.permiteReimpressaoCaixa = true;
            }
          }
          this.espera(false);
        },
        err => {
          //self.cliente = null;
          this.espera(false);
          console.log(err);
        }
      );
  }

  private espera(ativa: boolean) {
    this.ativar_spinner = ativa;
  }

  private alterarCaixa(status: number) {
    
    this.registroFinanceiro.statusRegistro = status;

    this.espera(true);

    this.registroFinanceiroServico.salvar(this.registroFinanceiro, this.usuarioServico.usuario.id)
      .subscribe(
        retorno => {
          this.registroFinanceiro = retorno;
          this.espera(false);

          if (status == 1) {
            this.mensagem = "Caixa fechado com sucesso.";
            this.permiteReimpressaoCaixa = true;

            $(() => {
              $('#modalFechamentoCaixa').modal('hide');

              window.setTimeout(function () { this.mensagem = "" }, 5000);
            })
          }
          else if (status == 2) {
            this.mensagem = "Caixa aberto com sucesso.";
            this.permiteReimpressaoCaixa = false;
            $(() => {
              $('#modalAberturaCaixa').modal('hide');

              window.setTimeout(function () { this.mensagem = "" }, 5000);
            })
          }
        },
        err => {
          this.espera(false);
          console.log(err);
        }
      );

    this.updateTooltip();
  }

  private AdicionarValor(adicao) {
    this.adicionarMovimentacao = adicao;

    $(() => {
      $('#modalAdicionarValor').modal('show');
      $('#modalAdicionarValor')
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {
          $('#fecharModalConsultaPedido').focus();
        });

      $('#modalAdicionarValor')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {

        });
    });
  }

  private SalvarMovimentacaoFinanceira() {
    this.espera(true);

    if (!this.adicionarMovimentacao) {
      this.movimentacaoFinanceira.valor = this.movimentacaoFinanceira.valor * -1;
    }

    this.movimentacaoFinanceira.usuarioId = this.usuarioServico.usuario.id;
    this.movimentacaoFinanceira.registroFinanceiroId = this.registroFinanceiro.id;

    this.registroFinanceiroServico.salvarMovimentacaoFinanceira(this.movimentacaoFinanceira)
      .subscribe(
        retorno => {
          this.obterRegistroAtual();
          this.espera(false);
          this.imprimirLancamentoCaixa(this.movimentacaoFinanceira);
          this.movimentacaoFinanceira = new MovimentacaoFinanceira();

          this.mensagem = "Valor atribuído com sucesso.";
          $(() => {
            $('#modalAdicionarValor').modal('hide');

            window.setTimeout(function () { this.mensagem = "" }, 5000);
          })
        },
        err => {
          this.espera(false);
          console.log(err);
        }
      );

    this.updateTooltip();
  }

  private preencheListaEntregadores() {
    var self = this;

    this.entregadorServico.obterTodosEntregadores().subscribe(
      entregadores => {
        self.listaEntregadoresAtivos = new List<Entregador>(entregadores).Where(e => e.ativo == true).ToArray();
        self.listaEntregadoresAtivos.map(e => {
          e.search_label = `${e.nome} ${e.apelido} ${e.telefone} ${e.contrato}`;
          return e;
        });

      },
      erro => { self.mensagem = erro.error; }
    );
  }

  private relatorioEntregadores() {
    this.preencheListaEntregadores();
    this.updateTooltip();

    $(() => {
      $('#modalEntregadores').modal('show');

      $('#modalEntregadores')
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {
          $('#lstEntregadores').find('input').focus();
        });


      $('#modalEntregadores')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {
          this.entregadorSelecionadoId = 0;
        });
    });
  }

  private limparSelecaoEntregador() {
    this.entregadorSelecionadoId = 0;
  }

  private async gerarRelatorioEntregadores() {
    while (this.pedidoServico.imprimindo) {
      await this.sleep(1000);
    }

    this.pedidoServico.imprimindo = true;

    this.entregadorServico.imprimirRelatorioEntregadores(this.entregadorSelecionadoId).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            console.log(retorno);
            this.pedidoServico.imprimindo = false;
          },
          error => {
            console.log(error);
            this.pedidoServico.imprimindo = false;
          }
        )
      },
      error => {
        this.mensagem = error;
      }
    )
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async gerarRelatorioEntregadoresData() {
    while (this.pedidoServico.imprimindo) {
      await this.sleep(1000);
    }

    this.pedidoServico.imprimindo = true;

    var dadosRelatorioEntregador = new DadosRelatorioEntregador();
    dadosRelatorioEntregador.entregadorId = this.entregadorSelecionadoId;
    dadosRelatorioEntregador.dataBase = this.dataSelecionada;
    //let latest_date = this.datepipe.transform(this.dataSelecionada, 'yyyy-MM-dd');

    this.entregadorServico.imprimirRelatorioEntregadoresData(dadosRelatorioEntregador).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            console.log(retorno);
            this.pedidoServico.imprimindo = false;
          },
          error => {
            console.log(error);
            this.pedidoServico.imprimindo = false;
          }
        )
      },
      error => {
        this.mensagem = error;
      }
    )
  }

  private AberturaCaixa() {
    this.updateTooltip();

    $(() => {
      $('#modalAberturaCaixa').modal('show');
      $('#modalAberturaCaixa')
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {
          $('#fecharModalConsultaPedido').focus();
        });

      $('#modalAberturaCaixa')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {

        });
    });
  }

  private FechamentoCaixa() {
    this.updateTooltip();

    this.ativar_spinner = true;

    this.pedidoServico.consultarRelatorioDiario().subscribe(
      relatorio => {
        this.ativar_spinner = false;
        this.relatorioFechamentoCaixa = relatorio;
      },
      erro => {
        console.log(erro.error);
        this.ativar_spinner = false;
      }
    );

    $(() => {
      $('#modalFechamentoCaixa').modal('show');
      $('#modalFechamentoCaixa')
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {
          $('#fecharModalConsultaPedido').focus();
        });

      $('#modalFechamentoCaixa')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {

        });
    });
  }

  private AdicionarMassa() {
    this.updateTooltip();

    $(() => {
      $('#modalAdicionarMassa').modal('show');
      $('#modalAdicionarMassa')
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {
          $('#fecharModalConsultaPedido').focus();
        });

      $('#modalAdicionarMassa')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {

        });
    });
  }

  private SalvarQtdeMassa() {
    this.espera(true);

    this.registroFinanceiroServico.adicionarMassa(this.registroFinanceiro.id, (this.registroFinanceiro.qtdeMassas + this.qtdeMassaParaAdicionar))
      .subscribe(
        retorno => {
          this.obterRegistroAtual();
          this.espera(false);

          this.mensagem = "Valor atribuído com sucesso.";
          $(() => {
            $('#modalAdicionarValor').modal('hide');

            window.setTimeout(function () { this.mensagem = "" }, 5000);
          })
        },
        err => {
          this.espera(false);
          console.log(err);
        }
      );

    this.updateTooltip();
  }

  private async imprimirFechamentoCaixa() {
    while (this.pedidoServico.imprimindo) {
      await this.sleep(1000);
    }

    this.pedidoServico.imprimindo = true;

    this.relatorioFechamentoCaixaManual.usuarioId = this.usuarioServico.usuario.id;
    this.pedidoServico.imprimirFechamentoCaixa(this.relatorioFechamentoCaixaManual).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            console.log(retorno);
            this.pedidoServico.imprimindo = false;
          },
          error => {
            console.log(error);
            this.pedidoServico.imprimindo = false;
          }
        )
      },
      error => {
        this.mensagem = error;
      }
    )
  }

  private gerarRelatorioTodosEntregadores() {
    this.limparSelecaoEntregador();
    this.gerarRelatorioEntregadores();
  }

  private async imprimirLancamentoCaixa(movimentacaoFinanceira) {
    while (this.pedidoServico.imprimindo) {
      await this.sleep(1000);
    }

    this.pedidoServico.imprimindo = true;

    this.registroFinanceiroServico.imprimirLancamentoCaixa(movimentacaoFinanceira).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            console.log(retorno);
            this.pedidoServico.imprimindo = false;
          },
          error => {
            console.log(error);
            this.pedidoServico.imprimindo = false;
          }
        )
      },
      error => {
        this.mensagem = error;
      }
    )
  }

  private preencherSaldoComSaldoAnterior() {
    this.espera(true);

    this.registroFinanceiroServico.obterRegistroAtual()
      .subscribe(
        retorno => {

          this.registroFinanceiro = retorno;

          if (this.aproveitarSaldo)
            this.registroFinanceiro.saldoAtual = this.registroFinanceiro.saldoAnterior;
          else
            this.registroFinanceiro.saldoAtual = 0;

          this.espera(false);
        },
        err => {
          //self.cliente = null;
          this.espera(false);
          console.log(err);
        }
      );
  }
}
