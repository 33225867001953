import { Component, OnInit } from "@angular/core";
import { Sabor } from "../../model/sabor";
import { SaborServico } from "../../servicos/sabor/sabor.servico";
import { Router } from "@angular/router";

@Component({
  selector: "pesquisa-sabor",
  templateUrl: "./pesquisa.sabor.component.html",
  styleUrls: ["./pesquisa.sabor.component.css"]
})

export class PesquisaSaborComponent implements OnInit {

  public sabores: Sabor[];
  pag: Number = 1;
  contador: Number = 5;
  searchText;

  ngOnInit(): void {

  }

  constructor(private saborServico: SaborServico, private router: Router) {
    this.saborServico.obterTodosSabores()
      .subscribe(
        sabores => {
          this.sabores = sabores;
        },
        err => {
          console.log(err.error);
        }
      )
  }

  public adicionarSabor() {
    sessionStorage.setItem('saborSession', '');
    this.router.navigate(['/cadastrar-sabor']);
  }

  public excluirSabor(sabor: Sabor) {
    var retorno = confirm("Deseja realmente excluir o sabor selecionado?");
    if (retorno == true) {
      this.saborServico.excluir(sabor).subscribe(
        sabores => {
          this.sabores = sabores;
        },
        err => {
          console.log(err.error);
        }
      );
    }
  }

  public editarSabor(sabor: Sabor) {
    sessionStorage.setItem('saborSession', JSON.stringify(sabor));
    this.router.navigate(['/cadastrar-sabor']);
  }
}
