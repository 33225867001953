export enum ContratoEnum {
  CLT = 1,
  PJ = 2,
  Temporario = 3
}

export class Entregador {
  id: number;
  codigo: number;
  nome: string;
  apelido: string;
  telefone: string;
  ativo: boolean;
  contrato: ContratoEnum;
  comissao: number;
  search_label: string;
}
