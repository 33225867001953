import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Sabor } from "../../model/sabor";

@Injectable({
  providedIn: "root"
})
export class SaborServico implements OnInit {

  private baseURL: string;
  public sabores: Sabor[];

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {
    this.sabores = [];
  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  public cadastrar(sabor: Sabor): Observable<Sabor> {
    return this.http.post<Sabor>(this.baseURL + "api/sabor", JSON.stringify(sabor), { headers: this.headers });
  }

  public salvar(sabor: Sabor): Observable<Sabor> {
    return this.http.post<Sabor>(this.baseURL + "api/sabor/salvar", JSON.stringify(sabor), { headers: this.headers });
  }

  public excluir(sabor: Sabor): Observable<Sabor[]> {
    return this.http.post<Sabor[]>(this.baseURL + "api/sabor/excluir", JSON.stringify(sabor), { headers: this.headers });
  }

  public obterTodosSabores(): Observable<Sabor[]> {
    return this.http.get<Sabor[]>(this.baseURL + "api/sabor");
  }

  public obterSabor(sabor: Sabor): Observable<Sabor> {
    return this.http.get<Sabor>(this.baseURL + "api/sabor/obter-por-id/" + sabor.id);
  }

  public enviarArquivo(arquivoSelecionado: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append("arquivoEnviado", arquivoSelecionado, arquivoSelecionado.name);
    return this.http.post<string>(this.baseURL + "api/sabor/enviarArquivo", formData);
  }
}
