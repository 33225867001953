import { Component, OnInit } from "@angular/core";
import { EntregadorServico } from "../../servicos/entregador/entregador.servico";
import { Router, ActivatedRoute } from "@angular/router";
import { Entregador } from "../../model/entregador";

@Component({
  selector: "cadastrar-entregador",
  templateUrl: "./cadastro.entregador.component.html",
  styleUrls: ["./cadastro.entregador.component.css"]
})

export class CadastrarEntregadorComponent implements OnInit {

  public entregador: Entregador = new Entregador();
  public mensagem: string;
  public ativar_spinner: boolean;
  //public mask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  //public mask = ['(', /[1-9]/, /\d/, ')', ' ', /\d{4,5}/, '-', /\d{4}/];

  public mask = function (rawValue) {
    var numbers = rawValue.match(/\d/g);
    var numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 10) {
      return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }

  ngOnInit(): void {

  }

  constructor(private entregadorServico: EntregadorServico, private route: ActivatedRoute, private router: Router) {
    var id = 0;
    var self = this;
    //recupera o numeroPedido se vier pela url
    this.route.params.subscribe(
      params => {
        if (params.id !== undefined) {
          id = parseInt(params.id);
          entregadorServico.obterEntregador(id).subscribe(entregador => { self.entregador = entregador });
        }
      });

  }

  
  cadastrar() {
    this.ativar_spinner = true;
    this.entregador.ativo = true;
    
    this.entregadorServico.cadastrar(this.entregador).subscribe(
      e => {
        this.router.navigate(['/pesquisar-entregador']);
        this.ativar_spinner = false;
      },
      erro => {
        this.mensagem = erro.error;
        this.ativar_spinner = false;
      }
    );
    
  }
}
