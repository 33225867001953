import { Component, OnInit } from "@angular/core"
import { Ingrediente } from "../model/ingrediente";
import { IngredienteServico } from "../servicos/ingrediente/ingrediente.servico";
import { Router } from "@angular/router";

@Component({
  selector: "app-ingrediente",
  templateUrl: "./ingrediente.component.html",
  styleUrls: ["./ingrediente.component.css"]
})
export class IngredienteComponent implements OnInit {
  public ingrediente: Ingrediente;
  public arquivoSelecionado: File;
  public ativar_spinner: boolean;
  public mensagem: string;

  constructor(private ingredienteServico: IngredienteServico, private router: Router) {

  }

  ngOnInit(): void {
    var ingredienteSession = sessionStorage.getItem('ingredienteSession');

    if (ingredienteSession) {
      this.ingrediente = JSON.parse(ingredienteSession);
    }
    else {
      this.ingrediente = new Ingrediente();
    }
  }

  public inputChange(files: FileList) {
    this.arquivoSelecionado = files.item(0);
    this.ativarEspera();
    this.ingredienteServico.enviarArquivo(this.arquivoSelecionado)
      .subscribe(
        nomeArquivo => {
          this.ingrediente.nomeArquivo = nomeArquivo;
          this.desativarEspera();
        },
        err => {
          console.log(err.error);
          this.desativarEspera();
        }
      );
  }

  public cadastrar() {
    this.ativarEspera();
    this.ingredienteServico.cadastrar(this.ingrediente)
      .subscribe(
        ingredienteRetorno => {
          sessionStorage.removeItem('ingredienteSession');
          this.desativarEspera();
          this.router.navigate(['/pesquisar-ingrediente']);
        },
        err => {
          this.mensagem = err.error;
          this.desativarEspera();
        }
      );
  }

  public ativarEspera() {
    this.ativar_spinner = true;
  }

  public desativarEspera() {
    this.ativar_spinner = false;
  }
}
