import { Component, OnInit } from "@angular/core";
import { Receita } from "../../model/receita";
import { ReceitaServico } from "../../servicos/receita/receita.servico";
import { Router } from "@angular/router";
import { Sabor } from "../../model/sabor";
import { SaborServico } from "../../servicos/sabor/sabor.servico";

@Component({
  selector: "pesquisa-receita",
  templateUrl: "./pesquisa.receita.component.html",
  styleUrls: ["./pesquisa.receita.component.css"]
})

export class PesquisaReceitaComponent implements OnInit {
  public receitas: Receita[];
  public sabores: Sabor[];
  public saborSelecionado: Sabor = null;
  public ativar_spinner: boolean = false;
  pag: Number = 1;
  contador: Number = 5;

  ngOnInit(): void {
    this.receitas = [];
  }

  constructor(private receitaServico: ReceitaServico, private router: Router, private saborServico: SaborServico) {
    this.ativar_spinner = true;
    this.receitaServico.obterTodasReceitas()
      .subscribe(
        receitas => {
          this.receitas = receitas;
          this.ativar_spinner = false;
        },
        err => {
          console.log(err.error);
          this.ativar_spinner = false;
        }
    )

    this.saborServico.obterTodosSabores()
      .subscribe(
        sabores => {
          this.sabores = sabores;
          this.ativar_spinner = false;
        },
        err => {
          console.log(err.error);
          this.ativar_spinner = false;
        }
      )
  }

  public adicionarReceita() {
    sessionStorage.setItem('receitaSession', '');
    this.router.navigate(['/cadastrar-receita']);
  }

  public excluirReceita(receita: Receita) {
    var retorno = confirm("Deseja realmente excluir a receita selecionada?");
    this.ativar_spinner = true;
    if (retorno == true) {
      this.receitaServico.excluir(receita).subscribe(
        receitas => {
          this.receitas = receitas;
          this.ativar_spinner = false;
        },
        err => {
          console.log(err.error);
          this.ativar_spinner = false;
        }
      );
    }
  }

  public editarReceita(receita: Receita) {
    sessionStorage.setItem('receitaSession', JSON.stringify(receita));
    this.router.navigate(['/cadastrar-receita']);
  }

  public filtrarPorSabor(sabor) {
    this.ativar_spinner = true;
    if (sabor == null) {
      this.receitaServico.obterTodasReceitas()
        .subscribe(
          receitas => {
            this.receitas = receitas;
            this.ativar_spinner = false;
          },
          err => {
            console.log(err.error);
            this.ativar_spinner = false;
          }
        )
    }
    else {
      this.receitaServico.obterReceitasPorSabor(sabor.id)
        .subscribe(
          receitas => {
            this.receitas = receitas;
            this.ativar_spinner = false;
          },
          err => {
            console.log(err.error);
            this.ativar_spinner = false;
          }
        )
    }
  }
}
