import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Entregador } from "../../model/entregador";
import { DadosImpressao } from "../../model/dadosImpressao";
import { DadosRelatorioEntregador } from "../../model/dadosRelatorioEntregador";

@Injectable({
  providedIn: "root"
})

export class EntregadorServico implements OnInit {
  
  private baseURL: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {
  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  public cadastrar(entregador: Entregador): Observable<Entregador> {
    return this.http.post<Entregador>(this.baseURL + "api/entregador", JSON.stringify(entregador), { headers: this.headers });
  }

  public obterTodosEntregadores(): Observable<Entregador[]> {
    return this.http.get<Entregador[]>(this.baseURL + "api/entregador");
  }

  public obterEntregador(id: number): Observable<Entregador> {
    return this.http.get<Entregador>(this.baseURL + "api/entregador/" + id);
  }

  public excluir(entregador: Entregador) : Observable<any> {
    return this.http.post(this.baseURL + "api/entregador/excluir", JSON.stringify(entregador), { headers: this.headers });
  }

  public buscaSaldo(id: number): Observable<number> {
    return this.http.get<number>(this.baseURL + `api/entregador/BuscaSaldo/${id}`);
  }

  public extratoEntregas(id: number): Observable<number> {
    return this.http.get<number>(this.baseURL + `api/entregador/ExtratoEntregas/${id}`);
  }

  public imprimirRelatorioEntregadores(id: number): Observable<DadosImpressao> {
    return this.http.get<DadosImpressao>(this.baseURL + "api/entregador/imprimir-relatorio-entregadores/" + id);
  }

  public imprimirRelatorioEntregadoresData(dadosRelatorioEntregador: DadosRelatorioEntregador): Observable<any> {
    return this.http.post(this.baseURL + "api/entregador/imprimir-relatorio-entregadores-data", JSON.stringify(dadosRelatorioEntregador), { headers: this.headers });
  }
}
