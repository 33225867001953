import { Pedido } from "./pedido";
import { Entregador } from "./entregador";

export class PedidoEntregador {
  constructor() {
    this.pedido = new Pedido();
    this.entregador = new Entregador();
  }

  pedido: Pedido;
  entregador: Entregador;
}
