import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router";
import { Chart } from "chart.js"
import { DadosRelatorio } from "../../model/dadosRelatorio";
import { RelatorioServico } from "../../servicos/relatorio/relatorio.servico";
import { loadCldr, L10n } from "@syncfusion/ej2-base";

//variavel para utilizar jquery
declare var $: any;
declare var require: any;

loadCldr(
  require("cldr-data/main/pt/numbers.json"),
  require("cldr-data/main/pt/ca-gregorian.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/pt/timeZoneNames.json"),
  require('cldr-data/supplemental/weekdata.json') // To load the culture based first day of week
);

@Component({
  selector: "app-entregadores-relatorio",
  templateUrl: "./entregadores.relatorio.component.html",
  styleUrls: ["./entregadores.relatorio.component.css"]
})
export class EntregadoresRelatorioComponent implements OnInit {
  constructor(private router: Router, private relatorioServico: RelatorioServico, public datepipe: DatePipe) {

  }

  public chart: any;
  public dadosRelatorio: DadosRelatorio[];
  public ativar_spinner: boolean = false;
  public minDate: Date = new Date("01/01/2021");
  public maxDate: Date = new Date("12/31/2099");
  public dateValue: Date = new Date();
  public dataInicio: Date = new Date();
  public dataFim: Date = new Date();
  //public tipoPesquisa: number = 1;

  ngOnInit(): void {
    $(() => {
      $(document).ready(function () {

      })
    });
    //this.createChart();
  }

  createChart(relatorio) {
    this.chart = new Chart("MyChart", {
      type: 'bar',

      data: relatorio,
      options: {
        aspectRatio: 2.5
      }
    });
    this.chart.update();
  }

  gerarGrafico() {
    this.ativar_spinner = true;
    var relatorio = new DadosRelatorio();
    relatorio.dataInicio = this.dataInicio;
    relatorio.dataFim = this.dataFim;

    this.relatorioServico.obterEntregadoresMes(relatorio)
      .subscribe(
        relatorio => {
          if (this.chart)
            this.chart.destroy();

          this.createChart(relatorio);
          this.ativar_spinner = false;
        },
        err => {
          console.log(err.error);
          this.ativar_spinner = false;
        }
      )
  }

  gerarRelatorio() {
    this.ativar_spinner = true;
    var relatorio = new DadosRelatorio();
    relatorio.dataInicio = this.dataInicio;
    relatorio.dataFim = this.dataFim;

    this.relatorioServico.obterEntregadoresMesDiaDia(relatorio)
      .subscribe(
        relatorio => {
          this.relatorioServico.gerarExcelEntregadoresDiaDia(relatorio);
          this.ativar_spinner = false;
        },
        err => {
          console.log(err.error);
          this.ativar_spinner = false;
        }
      )
  }
}
