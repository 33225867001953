import { Component, OnInit } from "@angular/core"
import { Mercadoria } from "../../model/mercadoria";
import { MercadoriaServico } from "../../servicos/mercadoria/mercadoria.servico";
import { Router } from "@angular/router";

@Component({
  selector: "app-mercadoria",
  templateUrl: "./cadastro.mercadoria.component.html",
  styleUrls: ["./cadastro.mercadoria.component.css"]
})
export class CadastroMercadoriaComponent implements OnInit {
  public mercadoria: Mercadoria;
  public ativar_spinner: boolean;
  public mensagem: string;
  public mercadoriaExistente: boolean = false;

  constructor(private mercadoriaServico: MercadoriaServico, private router: Router) {

  }

  ngOnInit(): void {
    var mercadoriaSession = sessionStorage.getItem('mercadoriaSession');

    if (mercadoriaSession) {
      this.mercadoria = JSON.parse(mercadoriaSession);
    }
    else {
      this.mercadoria = new Mercadoria();
      this.mercadoria.medida = 0;
    }
  }

  public cadastrar() {
    this.ativarEspera();
    this.mercadoriaServico.cadastrar(this.mercadoria)
      .subscribe(
        mercadoriaRetorno => {
          console.log(mercadoriaRetorno);
          sessionStorage.removeItem('mercadoriaSession');
          this.desativarEspera();
          this.router.navigate(['/pesquisar-mercadoria']);
        },
        err => {
          this.mensagem = err.error;
          this.desativarEspera();
        }
      );
  }

  public verificarMercadoriaExistente() {

    this.ativarEspera();
    this.mercadoriaServico.obterMercadoria(this.mercadoria)
      .subscribe(
        mercadoriaRetorno => {
          if (mercadoriaRetorno == null) {
            this.mercadoriaExistente = false;
          }
          else {
            if (mercadoriaRetorno.length > 0)
              this.mercadoriaExistente = true;
            else
              this.mercadoriaExistente = false;
          }

          this.desativarEspera();
        },
        err => {
          this.mensagem = err.error;
          this.desativarEspera();
        }
      );
  }

  public ativarEspera() {
    this.ativar_spinner = true;
  }

  public desativarEspera() {
    this.ativar_spinner = false;
  }
}

