export class DadosRelatorio {
  quantidade: number;
  descricao: string;
  periodo: string;
  precoTotal: number;
  mesPedido: number;
  anoPedido: number;
  dataInicio: Date;
  dataFim: Date;
  tipoPesquisa: number;
  tipoRelatorio: number;
}
