/// <reference types="@types/googlemaps" />

import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { } from 'googlemaps';
import { List } from "linqts";
import { TaxaServico } from "../servicos/taxas/taxa.servico";
import { Taxa } from "../model/taxa";
import { MyPolygon } from "../model/myPolygon";
import { trigger, transition, style, animate } from '@angular/animations';
import { ConfiguracaoSistemaServico } from "../servicos/configuracaoSistema/configuracaoSistema.servico";



@Component({
  selector: 'app-taxasEntrega',
  templateUrl: './taxa-entrega.component.html',
  styleUrls: ['./taxa-entrega.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('0.3s ease-out', style({ height: 100, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: 100, opacity: 1 }),
        animate('0.3s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})


export class TaxaEntregaComponent implements OnInit {

  corEditable: string = "#FF0000";
  corNotEditable: string = "#0000CC";
  estaEditando: boolean = false;
  areaEditando: MyPolygon;
  listaAreas: MyPolygon[] = [];
  infoWindow: google.maps.InfoWindow;
  areaSelecionada: MyPolygon;
  salvarAreaExistente: boolean;
  mapClickEvent: google.maps.MapsEventListener;
  novaArea: MyPolygon;
  polyediting: any;
  areaConfigPadrao: {
    strokeColor: any,
    strokeOpacity: any,
    strokeWeight: any,
    fillColor: any,
    fillOpacity: any
  };
  loja: any



  @ViewChild('map') mapElement: any;
  //@ViewChild('map', {static: false}) mapElement: any;
  map: google.maps.Map;


  constructor(private http: HttpClient, private taxaServico: TaxaServico, private configuracaoSistemaServico: ConfiguracaoSistemaServico) {

  }

  ngOnInit(): void {
    this.configuracaoSistemaServico.obterTodos()
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            this.loja = { lat: -0.0, lng: -0.0 };
            data.forEach(config => {
              

              if (config.descricao == 'PosicaoLatitudeLoja') {
                this.loja.lat = Number(config.valor);
              }
              else if (config.descricao == 'PosicaoLongitudeLoja') {
                this.loja.lng = Number(config.valor);
              }
            });
            
            const mapProperties = {
              center: this.loja,
              zoom: 16,
              mapTypeId: google.maps.MapTypeId.TERRAIN,
              clickableIcons: false
            };

            this.defineConfigPadrao(false);

            this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
            this.infoWindow = new google.maps.InfoWindow();
            this.areaSelecionada = new MyPolygon();
            this.salvarAreaExistente = false;

            //como resolver o Marker descontinuado
            //https://developers.google.com/maps/documentation/javascript/load-maps-js-api

            new google.maps.Marker({
              animation: google.maps.Animation.DROP,
              position: this.loja,
              map: this.map
            });

            this.listarAreas();

          }
        },
        err => {
          console.log(err.error);
        }
      );


  }

  listarAreas() {
    var self = this;
    this.defineConfigPadrao(false);

    self.limparMapa();
    self.listaAreas = [];

    this.taxaServico.listarTaxas().subscribe(
      areas => {

        areas.forEach((taxa) => {
          self.listaAreas.push(self.criaArea(taxa));
        });

      },
      erro => {

      }
    );
  }


  defineConfigPadrao(estaEditando: boolean) {
    this.estaEditando = estaEditando;

    this.areaConfigPadrao = {
      strokeColor: (this.estaEditando) ? this.corEditable : this.corNotEditable,
      fillColor: (this.estaEditando) ? this.corEditable : this.corNotEditable,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillOpacity: 0.35
    };
  }


  limparMapa() {
    this.areaEditando = null;
    this.areaSelecionada = null;
    this.listaAreas.forEach((area) => {
      area.polygon.setMap(null);
      area.circle.setMap(null);
    });
    this.listaAreas = [];
  }


  getPathVariableCode(line, log: boolean = false): google.maps.LatLng[] {
    var pathArr = line.getPath();
    var poligonos: google.maps.LatLng[] = [];
    for (var i = 0; i < pathArr.length; i++) {
      if (log)
        console.log(line.nome, pathArr.getAt(i).lat(), pathArr.getAt(i).lng());

      poligonos.push(new google.maps.LatLng(pathArr.getAt(i).lat(), pathArr.getAt(i).lng()));

    };

    return poligonos;
  };


  addArea(isRaio = false) {

    var self = this;
    self.defineConfigPadrao(true);
    var ordens = new List(self.listaAreas);
    var ordem = 0;
    if (ordens.Count() > 0)
      ordem = ordens.OrderByDescending(x => x.ordem).FirstOrDefault().ordem;

    const latLng = [];

    if (self.ehObjetoVazio(self.novaArea))
      self.novaArea = new MyPolygon();



    self.novaArea.nome = `AREA NOME ${ordem + 1}`;
    self.novaArea.id = 0;
    self.novaArea.ordem = ordem + 1;
    self.novaArea.taxaValor = 0;


    if (isRaio) {

      self.novaArea.circle.setOptions({
        center: self.loja,
        radius: 1000,  // 1 km (1000 metros * qtd km)
        map: self.map,
        editable: true,
        draggable: false,
        fillColor: self.areaConfigPadrao.fillColor,
        strokeColor: self.areaConfigPadrao.strokeColor,
        fillOpacity: self.areaConfigPadrao.fillOpacity,
        strokeOpacity: self.areaConfigPadrao.strokeOpacity,
        strokeWeight: self.areaConfigPadrao.strokeWeight,
      });
      self.novaArea.tipo = 2;
      self.novaArea.raio = 1;


      self.salvarAreaExistente = false;
      self.areaEditando = self.novaArea;
      this.novaArea.circle.setRadius(1000 * self.areaEditando.raio);

      self.novaArea.circle.addListener('radius_changed', function () {
        self.areaEditando.raio = self.novaArea.circle.getRadius() / 1000;
      });


    } else {


      this.mapClickEvent = this.map.addListener('click', function (e) {

        latLng.push({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        self.novaArea.polygon.setOptions({
          paths: latLng,
          map: self.map,
          editable: true,
          draggable: true,
          fillColor: self.areaConfigPadrao.fillColor,
          strokeColor: self.areaConfigPadrao.strokeColor,
          fillOpacity: self.areaConfigPadrao.fillOpacity,
          strokeOpacity: self.areaConfigPadrao.strokeOpacity,
          strokeWeight: self.areaConfigPadrao.strokeWeight,
        });
        self.novaArea.tipo = 1;
        self.novaArea.raio = null;


        if (latLng.length >= 3) {
          self.salvarAreaExistente = false;
          self.areaEditando = self.novaArea;

        }

      });
      self.novaArea.polygon.addListener('overlaycomplete', function (e) {
        self.areaEditando.polygon.setOptions({ paths: e.getPath() });
      });
    }

  }

  alterarRaio(raio) {
    this.areaEditando.circle.setRadius(1000 * raio);
  }

  ehObjetoVazio(obj) {
    if (obj == undefined || obj == null)
      return true;

    return !Object.keys(obj).length;
  }


  criaArea(taxa: Taxa): MyPolygon {
    var self = this;
    var novaArea: MyPolygon = new MyPolygon();



    if (taxa.tipo == 1) {
      var paths = taxa.polygon.map(function (p) { return { "lat": p.lat, "lng": p.lng } });

      novaArea.polygon.setOptions({
        paths: paths,
        map: self.map,
        editable: false,
        draggable: false,
        fillColor: self.areaConfigPadrao.fillColor,
        strokeColor: self.areaConfigPadrao.strokeColor,
        fillOpacity: self.areaConfigPadrao.fillOpacity,
        strokeOpacity: self.areaConfigPadrao.strokeOpacity,
        strokeWeight: self.areaConfigPadrao.strokeWeight,
      });
      novaArea.polygon.addListener('click', function (event) {
        self.infoWindow.close();
        self.infoWindow.setContent(`${novaArea.nome}`);
        self.infoWindow.setPosition(event.latLng);
        self.infoWindow.open(self.map);
      });

    }

    if (taxa.tipo == 2) {
      novaArea.circle.setOptions({
        center: self.loja,
        radius: 1000 * taxa.raio,
        map: self.map,
        editable: false,
        draggable: false,
        fillColor: self.areaConfigPadrao.fillColor,
        strokeColor: self.areaConfigPadrao.strokeColor,
        fillOpacity: self.areaConfigPadrao.fillOpacity,
        strokeOpacity: self.areaConfigPadrao.strokeOpacity,
        strokeWeight: self.areaConfigPadrao.strokeWeight,
      });
      novaArea.circle.addListener('click', function (event) {
        self.infoWindow.close();
        self.infoWindow.setContent(`${novaArea.nome}`);
        self.infoWindow.setPosition(event.latLng);
        self.infoWindow.open(self.map);
      });

    }

    novaArea.nome = taxa.nome;
    novaArea.id = taxa.id;
    novaArea.ordem = taxa.ordem;
    novaArea.taxaValor = taxa.taxaValor;
    novaArea.tipo = taxa.tipo;
    novaArea.raio = taxa.raio;

    return novaArea;

  }


  consultaAreas() {
    this.listaAreas.forEach((i) => {
      this.getPathVariableCode(i, true);
    })
  }


  salvar(area: MyPolygon) {
    var self = this;
    this.defineConfigPadrao(false);

    if (!this.salvarAreaExistente) {
      if (this.mapClickEvent) this.mapClickEvent.remove();
      area = this.areaEditando;
    }
    var opts = {
      fillColor: this.areaConfigPadrao.fillColor,
      strokeColor: this.areaConfigPadrao.strokeColor,
      editable: false,
      draggable: false
    };

    area.polygon.setOptions(opts);
    area.circle.setOptions(opts);

    if (!this.salvarAreaExistente) {
      this.listaAreas.push(area);
      this.salvarAreaExistente = false;
      this.novaArea = null;
    }

    var taxa = new Taxa();
    taxa.id = area.id;
    taxa.ordem = area.ordem;
    taxa.nome = area.nome,
    taxa.taxaValor = area.taxaValor;
    taxa.raio = area.raio;
    taxa.tipo = area.tipo;

    if (area.tipo == 1)
      taxa.polygon = this.obtemLatLng(area.polygon);
    else if (area.tipo == 2)
      taxa.polygon = this.obtemLatLng(new google.maps.Polygon({ paths: this.circlePath(area.circle) }));

    this.taxaServico.salvarTaxas(taxa).subscribe(
      ok => {
        var a = ok;
        self.listarAreas();
      },
      erro => {
        var b = erro;
      });

    this.areaEditando = null;

  }


  editar(area: MyPolygon) {

    var self = this;

    this.defineConfigPadrao(true);
    this.salvarAreaExistente = true;
    this.areaSelecionada = new MyPolygon({ id: area.id, nome: area.nome, taxaValor: area.taxaValor, ordem: area.ordem, polygon: area.polygon, circle: area.circle, tipo: area.tipo, raio: area.raio });

    console.log(area);

    if (area.tipo == 1) {
      this.polyediting = self.deepClone(self.obtemLatLng(area.polygon));
      area.polygon.setOptions({ paths: self.deepClone(self.obtemLatLng(area.polygon)), fillColor: self.areaConfigPadrao.fillColor, strokeColor: self.areaConfigPadrao.strokeColor, editable: true });

    }

    if (area.tipo == 2) {
      //this.polyediting = self.deepClone(area.circle);
      area.circle.setOptions({ center: self.loja, radius: 1000 * area.raio, fillColor: self.areaConfigPadrao.fillColor, strokeColor: self.areaConfigPadrao.strokeColor, editable: true });
      area.circle.addListener('radius_changed', function () {
        self.areaEditando.raio = area.circle.getRadius() / 1000;
      });
    }

    this.areaEditando = area; 
  }


  private deepClone(o: any): any {
    if (o == null)
      return null;

    return JSON.parse(JSON.stringify(o));
  }


  cancelar(area: MyPolygon) {
    this.defineConfigPadrao(false);

    if (this.salvarAreaExistente) {

      if(area.tipo == 1)
        area.polygon.setOptions({
          paths: this.polyediting,
          fillColor: this.areaConfigPadrao.fillColor,
          strokeColor: this.areaConfigPadrao.strokeColor,
          editable: false
        });
      else
        area.circle.setOptions({
          center: this.loja,
          radius: 1000 * area.raio,
          fillColor: this.areaConfigPadrao.fillColor,
          strokeColor: this.areaConfigPadrao.strokeColor,
          editable: false
        });

      area.nome = this.areaSelecionada.nome;
      area.taxaValor = this.areaSelecionada.taxaValor;
      area.ordem = this.areaSelecionada.ordem;
      area.tipo = this.areaSelecionada.tipo;
      area.raio = this.areaSelecionada.raio;

    }
    else {

      if (this.mapClickEvent) this.mapClickEvent.remove();
      this.novaArea.polygon.setMap(null);
      this.novaArea.circle.setMap(null);
      this.novaArea = null;

    }

    this.areaSelecionada = new MyPolygon();
    this.areaEditando = null;
    this.salvarAreaExistente = false;
    this.polyediting = null;

    this.listarAreas();

  }


  excluir(area: MyPolygon) {
    var self = this;

    if (area.polygon)
      area.polygon.setMap(null);

    if (area.circle)
      area.circle.setMap(null);

    this.listaAreas = this.listaAreas.filter(a => a.id != area.id);
    this.taxaServico.remover(area.id).subscribe(
      ok => {
        self.listarAreas();
      }
    );
  }


  obtemLatLng(area: google.maps.Polygon) {
    var vertices = area.getPath();

    var pointsArray = [];
    for (var i = 0; i < vertices.getLength(); i++) {
      var xy = vertices.getAt(i);
      var item = {"ordem": i, "lat": xy.lat(), "lng": xy.lng() };
      pointsArray.push(item);
    }

    return pointsArray;
  }

  circlePath(circle: google.maps.Circle) {
    var numPts = 32;
    var path = [];

    for (var i = 0; i < numPts; i++) {
      var xy = google.maps.geometry.spherical.computeOffset(circle.getCenter(), circle.getRadius(), i * 360 / numPts);
      var latLng = {"ordem": i, "lat": xy.lat(), "lng": xy.lng() };
      path.push(latLng);

    }

    return path;

  }

}
