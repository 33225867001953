import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Cliente } from "../../model/cliente";
import { ClienteServico } from "../../servicos/cliente/cliente.servico";
import { FiltroCliente } from "../../model/filtroCliente";
import { Endereco } from "../../model/endereco";
import { EnderecoViaCep } from "../../model/enderecoViaCep";

//variavel para utilizar jquery
declare var $: any;

@Component({
  selector: "pesquisa-cliente",
  templateUrl: "./pesquisa.cliente.component.html",
  styleUrls: ["./pesquisa.cliente.component.css"]
})

export class PesquisaClienteComponent implements OnInit {

  public ativar_spinner: boolean = false;
  pag: Number = 1;
  contador: Number = 5;
  public documento: string;
  public telefone: string;
  public cep: string;
  public clientes: Cliente[];
  public clienteSelecionado: Cliente = new Cliente();
  public editandoEndereco: boolean = false;
  public editandoCliente: boolean = false;
  public novoTelefone: string;
  public nome: string;

  ngOnInit(): void {
    $(() => {
      $('#txtNome').focus();
    });
  }

  constructor(private router: Router, private clienteServico: ClienteServico) {

  }

  public pesquisarClientes() {
    this.ativar_spinner = true;
    var filtro = new FiltroCliente();
    this.editandoCliente = false;

    if (this.nome != null) {
      filtro.nome = this.nome;
    }

    if (this.documento != null) {
      filtro.cpfCnpj = this.documento.replace(/[^0-9]/g, '');
    }

    if (this.telefone != null) {
      var telefone = this.telefone.replace(/[^0-9]/g, '');

      if (telefone != null && telefone != "") {
        filtro.telefone = parseInt(telefone);
      }
    }

    if (this.cep != null) {
      filtro.cep = this.cep.replace(/[^0-9]/g, '');
    }

    this.clienteServico.buscaClientes(filtro)
      .subscribe(
        clientes => {
          this.clientes = clientes;
          this.pag = 1;
          this.ativar_spinner = false;
        },
        err => {
          this.ativar_spinner = false;
          console.log(err.error);
        }
      );
  }

  public cpfcnpjmask = function (rawValue) {
    var numbers = rawValue.match(/\d/g);
    var numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 11) {
      return [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    } else {
      return [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    }
  }

  public telefonemask = function (rawValue) {
    var numbers = rawValue.match(/\d/g);
    var numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 10) {
      return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }

  public cepmask = function (rawValue) {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }

  exibePopup(cliente: Cliente, nomePopup) {
    var self = this;

    this.clienteSelecionado = cliente;
    $(() => {
      $('#modal' + nomePopup).modal('show');
      $('#modal' + nomePopup)
        .off('shown.bs.modal')
        .on('shown.bs.modal', function () {
          $('#fecharModal' + nomePopup).focus();
        });

      $('#modal' + nomePopup)
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {
          self.clienteSelecionado = new Cliente();
        });
    });
  }

  atualizarEndereco(endereco: Endereco) {
    this.ativar_spinner = true;
    this.clienteServico.atualizaEndereco(endereco)
      .subscribe(
        retorno => {
          this.editandoEndereco = false;
          this.ativar_spinner = false;
        },
        err => {
          this.editandoEndereco = false;
          this.ativar_spinner = false;
          console.log(err.error);
        }
      );
  }

  atualizarCliente(cliente: Cliente) {
    this.ativar_spinner = true;

    if (cliente.cpfCnpj != null && cliente.cpfCnpj != "") {
      cliente.cpfCnpj = cliente.cpfCnpj.replace(/[^0-9]/g, '');
    }

    if (this.novoTelefone != null) {
      var telefone = this.novoTelefone.replace(/[^0-9]/g, '');

      if (telefone != null && telefone != "") {
        this.clienteServico.atualizaTelefone(telefone, cliente.telefones[0])
          .subscribe(
            retorno => {
              if (retorno) {
                cliente.telefones[0].ddd = parseInt(telefone.substring(0, 2));
                cliente.telefones[0].numero = parseInt(telefone.substring(2));
                this.clienteServico.atualizaCliente(cliente)
                  .subscribe(
                    retorno => {
                      this.editandoCliente = false;
                      this.ativar_spinner = false;
                      this.novoTelefone = null;
                    },
                    err => {
                      this.editandoCliente = false;
                      this.ativar_spinner = false;
                      console.log(err.error);
                    }
                  );
              }
              else {
                this.editandoCliente = false;
                this.ativar_spinner = false;
                alert("Esse telefone já existe na base de dados!");
              }

            },
            err => {
              this.editandoCliente = false;
              this.ativar_spinner = false;
              console.log(err.error);
            }
          );
      }
    }
    else {
      this.clienteServico.atualizaCliente(cliente)
        .subscribe(
          retorno => {
            this.editandoCliente = false;
            this.ativar_spinner = false;
          },
          err => {
            this.editandoCliente = false;
            this.ativar_spinner = false;
            console.log(err.error);
          }
        );
    }
  }

  public consultaCEP(endereco) {
    this.ativar_spinner = true;
    debugger;
    this.clienteServico.consultaCep(endereco.cep).subscribe(
      retornoCep => {

        if (retornoCep != null && !retornoCep.erro) {
          this.convertToEndereco(retornoCep, endereco);

          var enderecoDestino = `${endereco.logradouro} ${endereco.numero} ${endereco.cidade} ${endereco.uf}`;

          var geoCoder = new google.maps.Geocoder();
          var latlng: google.maps.LatLng;
          geoCoder.geocode({ address: enderecoDestino }, (results, status) => {
            debugger;
            if (status == "OK") {
              latlng = results[0].geometry.location;
              endereco.lat = latlng.lat();
              endereco.lng = latlng.lng();
            }
          });

          this.ativar_spinner = false;
        }
        else {
          this.ativar_spinner = false;
          alert("Endereço não foi encontrado.");
        }
      },
      err => {
        this.ativar_spinner = false;
        console.log(err.error);
      });
  }

  public convertToEndereco(enderecoViaCep: EnderecoViaCep, endereco: Endereco): void {
    //var e = new Endereco();
    endereco.bairro = enderecoViaCep.bairro;
    endereco.cep = endereco.cep;
    endereco.cidade = enderecoViaCep.localidade;
    endereco.complemento = enderecoViaCep.complemento;
    endereco.logradouro = enderecoViaCep.logradouro;
    endereco.uf = enderecoViaCep.uf;
    endereco.distancia = 0;
  }
}
