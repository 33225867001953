import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Produto } from "../../model/produto";
import { PedidoConstants } from "../../constants/pedido.constants";
import { Pedido, StatusEnum } from "../../model/pedido";
import { ProdutoServico } from "../../servicos/produto/produto.servico";
import { ProdutoSabor, TipoProdutoEnum } from "../../model/produtoSabor";
import { ItemPedido } from "../../model/itemPedido";
import { DragulaService } from "ng2-dragula";
import { Subscription } from "rxjs";
import { List } from "linqts";
import { Endereco } from "../../model/endereco";
import { forEach } from "@angular/router/src/utils/collection";
import { TaxaServico } from "../../servicos/taxas/taxa.servico";
import { formatDate, DatePipe } from "@angular/common";
import { Cliente } from "../../model/cliente";
import { PedidoServico } from "../../servicos/pedido/pedido.servico";
import { UsuarioServico } from "../../servicos/usuario/usuario.servico";
import { NgForm } from "@angular/forms";
import { PagamentoPedido } from "../../model/pagamentoPedido";
import { ClienteServico } from "../../servicos/cliente/cliente.servico";
import { ConfiguracaoSistemaServico } from "../../servicos/configuracaoSistema/configuracaoSistema.servico";


//variavel para utilizar jquery
declare var $: any;


@Component({
  selector: "app-faz-pedido",
  templateUrl: "./faz-pedido.component.html",
  styleUrls: ["./faz-pedido.component.css", "../../../../node_modules/dragula/dist/dragula.css"]
})

export class FazPedidoComponent implements OnInit, OnDestroy {

  public ativar_spinner: boolean = false;
  public clienteExiste: boolean = false;
  public listaItensPedido: ItemPedido[] = [];
  public listaProdutosDisponiveis: ProdutoSabor[] = [];
  public listaTodosProdutosDisponiveis: ProdutoSabor[] = [];
  public produtoSelecionado: ProdutoSabor = new ProdutoSabor();
  public numeroPedido: number = 0;
  public pedido: Pedido = new Pedido();
  public TipoProduto = TipoProdutoEnum;
  public quantidade: number = 0;
  public quantidadeFracionada: string = "";
  public observacao: string = "";
  //public observacaoGeral: string = "";
  public mensagem: string = "";
  public valorPedido: number = 0;
  public chaveLocalStorage: string = "";
  public pedidoValido: boolean = true;
  public partesPizza: number = 0;
  public subs = new Subscription();
  private controleAgrupamento: number;
  private latlng: google.maps.LatLng;
  public somenteTaxaBalcao: boolean = false;
  public telefoneCliente: string = "";
  public permiteNomeCliente: boolean = false;
  public cliente: Cliente;
  public formaPagamento: string = "0";
  public trocoPara: number = 0;
  public subTotalPedido: number = 0;
  public valorDesconto: number = 0;
  public valorDescontoInformado: number = 0;
  public processandoPedido: boolean = false;
  public ultimoAgrupamento: number = 0;
  public novaTaxa: number = 0;
  public ehBroto: boolean = false;
  public multiplicadorTaxa: number = 1;
  public permiteBalcao: boolean = false;
  @ViewChild('qtdeBebidaInput') qtdeBebidaInput;
  @ViewChild('qtdeBebidaInput') qtdeOutrosInput;

  constructor(private router: Router, private route: ActivatedRoute, private produtoService: ProdutoServico, private dragulaService: DragulaService,
    private taxaServico: TaxaServico, private datePipe: DatePipe, private pedidoServico: PedidoServico, private usuarioServico: UsuarioServico,
    private el: ElementRef, private clienteService: ClienteServico, private configuracaoSistemaServico: ConfiguracaoSistemaServico) {
  }

  ngOnInit(): void {
    //recupera o numeroPedido se vier pela url
    this.route.params.subscribe(
      params => {

        if (params.numeroPedido !== undefined) {
          this.numeroPedido = parseInt(params.numeroPedido);
        }

        if (params.novoPedido !== undefined) {
          if (params.novoPedido == "true") {
            this.permiteNomeCliente = true;
            this.numeroPedido = parseInt(this.datePipe.transform(new Date(), "yyyyMMddHHmmss", "-0300"));
            this.iniciarPedido();
            this.pedido.balcao = true;
            this.pedido.cliente.somenteTaxaBalcao = true;
            this.pedido.cliente.balcao = true;
            this.pedido.cliente.saldo = 0;

            this.chaveLocalStorage = PedidoConstants.CHAVE_PEDIDO_LOCAL_STORAGE.format({ numeroPedido: this.numeroPedido });
            localStorage.setItem(this.chaveLocalStorage, JSON.stringify(this.pedido));
          }
        }
      });

    this.chaveLocalStorage = PedidoConstants.CHAVE_PEDIDO_LOCAL_STORAGE.format({ numeroPedido: this.numeroPedido });
    var p = <Pedido>JSON.parse(localStorage.getItem(this.chaveLocalStorage));
    if (p) {
      if (p.cliente != null && p.cliente.telefones != null && p.cliente.telefones.length > 0) {
        this.telefoneCliente = " - Telefone: (" + p.cliente.telefones[0].ddd + ")" + p.cliente.telefones[0].numero;
      }

      p = this.aplicarDesconto(p);

      this.pedido = p;
      this.validaCliente(true);
      //this.criaProdutos();
      this.updateTooltip();

      if (p.itensPedido !== undefined) {
        p.itensPedido.forEach((itemPedido, i) => {
          this.listaItensPedido.push(itemPedido);
        });
      }
      else {
        p.itensPedido = [];
      }

      this.somenteTaxaBalcao = p.cliente.somenteTaxaBalcao;
      this.atualizaPedido();
    }
    else {
      this.validaCliente(false);

    }

    this.produtosDisponiveis();
    //this.setFocusItemPedido();

    var drake = this.dragulaService.createGroup("DRAG", {
      removeOnSpill: false,
      direction: 'vertical'
    });

    var self = this;

    drake.drake.on("drop", function (name, el, target, source, sibling) {

      self.atualizaPedido();
    });

    this.configuracaoSistemaServico.obterTodos()
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            data.forEach(config => {
              if (config.descricao == 'MultiplicadorTaxa') {
                this.multiplicadorTaxa = Number(config.valor);
              }
              else if (config.descricao == 'PermiteBalcao') {
                if (config.valor == 'true') {
                  this.permiteBalcao = true;
                }
                else {
                  this.permiteBalcao = false;
                }
              }
            });
          }

          if (p) {
            this.atualizaPedido();
            if (this.permiteNomeCliente) {
              this.setFocusNomeCliente();
            }
          }
        },
        err => {
          console.log(err.error);
        }
      );

    this.espera(false);

    if (this.permiteNomeCliente) {
      this.setFocusNomeCliente();
    }
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy("DRAG");
  }

  public somaPartes(parte: number) {
    this.partesPizza += parte;
    if (this.partesPizza >= 1) {
      this.controleAgrupamento++;
      this.partesPizza = 0;
      return true;
    }
    return false;
  }



  public updateTooltip() {

    //jquery
    $(() => {
      $('[data-toggle=tooltip]').tooltip('dispose');
      $('[data-toggle=tooltip]').tooltip({ container: 'body' });
    });

  }


  produtosDisponiveis() {
    var self = this;
    this.produtoService.obterTodosProdutosSabores().subscribe(
      produtoSabor => {
        this.listaTodosProdutosDisponiveis = produtoSabor.map(function (p) {
          p.nomePesquisa = `${p.saborId} ${self.TipoProduto[p.tipoProduto]} ${p.nome} ${p.descricao || ''}`;
          return p;
        });

        this.filtrarProdutosDisponiveis();
      }
    );
  }

  filtrarProdutosDisponiveis() {
    if (this.ehBroto)
      this.listaProdutosDisponiveis = this.listaTodosProdutosDisponiveis.filter(ps => ps.tipoProduto != TipoProdutoEnum.PizzaSalgada && ps.tipoProduto != TipoProdutoEnum.PizzaDoceGrande);
    else
      this.listaProdutosDisponiveis = this.listaTodosProdutosDisponiveis.filter(ps => ps.tipoProduto != TipoProdutoEnum.PizzaSalgadaBroto && ps.tipoProduto != TipoProdutoEnum.PizzaDoceBroto);
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    var ehNumero = !isNaN(Number(term));

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      if (ehNumero) {
        let search = item['id'];
        isWordThere.push(search == Number(arr_term));
      } else {
        let search = item['nomePesquisa'].toLowerCase();
        isWordThere.push(search.indexOf(arr_term) != -1);
      }

    });

    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }

  public produtoSaborParaItemPedido(): ItemPedido {

    var itemPedido = new ItemPedido();
    if (this.produtoSelecionado != null) {
      if (this.quantidadeFracionada != undefined && this.quantidadeFracionada != "") {
        itemPedido.quantidadeFracionada = this.quantidadeFracionada;
        if (this.quantidadeFracionada.indexOf("/") > -1) {
          let valores = this.quantidadeFracionada.split('/').filter(t => t);
          this.quantidade = this.parseFloat((parseInt(valores[0]) / parseInt(valores[1])).toFixed(2));
        }
        else {
          this.quantidadeFracionada = this.quantidadeFracionada.replace(",", ".");
          this.quantidade = this.parseFloat(this.quantidadeFracionada);
        }
      }

      itemPedido.quantidade = this.quantidade;
      itemPedido.precoCheio = this.produtoSelecionado.precoCheio;
      itemPedido.preco = parseFloat((this.produtoSelecionado.precoCheio * this.quantidade).toFixed(2));
      itemPedido.nomeProduto = this.produtoSelecionado.nome;
      itemPedido.tipoProdutoId = this.produtoSelecionado.tipoProduto;
      itemPedido.produtoId = this.produtoSelecionado.produtoId;
      itemPedido.observacao = this.observacao;

      //if (itemPedido.tipoProdutoId == 1) {
      //  if (this.observacao == "")
      //    itemPedido.observacao = this.observacaoGeral;
      //  else
      //    itemPedido.observacao = this.observacao + " | " + this.observacaoGeral;
      //}

      if (this.produtoSelecionado.tipoProduto != TipoProdutoEnum.Bebida) {
        itemPedido.saborId = this.produtoSelecionado.saborId;
      }

    }

    return itemPedido;

  }

  public validaCliente(existe: boolean) {
    this.clienteExiste = existe;
  }

  public voltar() {
    this.espera(true);
    this.router.navigate([`/identifica-cliente/${this.numeroPedido}`]);
    this.espera(false);
  }

  public removerItem(itemPedido: ItemPedido) {
    this.listaItensPedido = this.listaItensPedido.filter(ip => ip.id != itemPedido.id);
    this.atualizaPedido();
    this.updateTooltip();
  }

  public adicionarItem() {

    var itemPedido = this.produtoSaborParaItemPedido();

    if (itemPedido != null) {

      //this.listaItensPedido = this.listaItensPedido.sort(ip => ip.ordemAgrupamento);

      var agrupamentos = new Map<number, number>();

      for (const { ordemAgrupamento, quantidade } of this.listaItensPedido)
        agrupamentos.set(ordemAgrupamento, (agrupamentos.get(ordemAgrupamento) || 0) + quantidade);

      var itemOrdenado = false;

      agrupamentos.forEach((value: number, key: number) => {
        if (!itemOrdenado)
          itemPedido.ordemAgrupamento = key;

        if (value >= 0.95 && !itemOrdenado) {
          itemPedido.ordemAgrupamento++;
        }
        else {
          if ((value + itemPedido.quantidade) > 1 && !itemOrdenado) {
            itemPedido.ordemAgrupamento++;
          }
          else {
            if ((value + itemPedido.quantidade) > 0.95 && (value + itemPedido.quantidade) < 1) {
              itemPedido.quantidade = 1 - value;
              itemPedido.quantidade = this.parseFloat(itemPedido.quantidade.toFixed(2));
            }

            itemOrdenado = true;
          }
        }
      });

      this.listaItensPedido.push(itemPedido);

      this.listaItensPedido.sort((a, b) => a.ordemAgrupamento - b.ordemAgrupamento);
      this.limpaProdutoSelecionado();
      this.atualizaPedido();
      this.updateTooltip();

    }
  }

  setFocusItemPedido() {
    $(() => {
      $('#lstSabor').find('input').focus();
      $('.tooltip.fade.bs-tooltip-top').remove();
    });
  }

  setFocusNomeCliente() {
    $(() => {
      $('#txtClienteNome').focus();
    });
  }

  public atualizaPedido() {

    var self = this;
    this.pedido.itensPedido = [];
    this.valorPedido = 0;
    var somaQuantidades = 0;

    this.controleAgrupamento = 0;
    var agrupamento = 1;
    var somaPartes = 0;
    var itemAnteriorCompleto = false;


    for (var oItemPedido of <ItemPedido[]>this.listaItensPedido) {

      if (oItemPedido.quantidadeFracionada != undefined && oItemPedido.quantidadeFracionada != "") {
        if (oItemPedido.quantidadeFracionada.indexOf("/") > -1) {
          let valores = oItemPedido.quantidadeFracionada.split('/').filter(t => t);
          oItemPedido.quantidade = this.parseFloat((parseInt(valores[0]) / parseInt(valores[1])).toFixed(2));
        }
        else {
          oItemPedido.quantidadeFracionada = oItemPedido.quantidadeFracionada.replace(",", ".");
          oItemPedido.quantidade = this.parseFloat(oItemPedido.quantidadeFracionada);
        }
      }

      if ((somaPartes + oItemPedido.quantidade) > 0.95 && (somaPartes + oItemPedido.quantidade) < 1) {
        oItemPedido.quantidade = 1 - somaPartes;
        oItemPedido.quantidade = this.parseFloat(oItemPedido.quantidade.toFixed(2));
      }

      somaPartes += oItemPedido.quantidade;

      if (somaPartes >= 0.95) {
        if (oItemPedido.tipoProdutoId == 4) {
          if (itemAnteriorCompleto)
            oItemPedido.ordemAgrupamento = agrupamento;
          else
            oItemPedido.ordemAgrupamento = agrupamento += 1;
        }
        else {
          itemAnteriorCompleto = true;
          oItemPedido.ordemAgrupamento = agrupamento;
        }

        agrupamento++;
        somaPartes = 0;
      }
      else {
        oItemPedido.ordemAgrupamento = agrupamento;
        itemAnteriorCompleto = false;
      }

      if (this.pedido.balcao || oItemPedido.tipoProdutoId == 4 || oItemPedido.tipoProdutoId == 12) {
        oItemPedido.preco = parseFloat((oItemPedido.precoCheio * oItemPedido.quantidade).toFixed(2));
      }
      else {
        oItemPedido.preco = parseFloat(((oItemPedido.precoCheio * oItemPedido.quantidade) * this.multiplicadorTaxa).toFixed(2));
      }

      //if (self.observacaoGeral != undefined && self.observacaoGeral != "") {
      //  if (oItemPedido.tipoProdutoId == 1) {
      //    if (oItemPedido.observacao == undefined || oItemPedido.observacao == "")
      //      oItemPedido.observacao = self.observacaoGeral;
      //    else {
      //      if (oItemPedido.observacao.indexOf(self.observacaoGeral) == -1)
      //        oItemPedido.observacao = oItemPedido.observacao + " | " + self.observacaoGeral;
      //    }
      //  }
      //}

      this.pedido.itensPedido.push(oItemPedido);
      this.valorPedido += oItemPedido.preco;
      somaQuantidades += oItemPedido.quantidade;
    }

    this.ultimoAgrupamento = Math.max.apply(Math, this.pedido.itensPedido.map(function (o) { return o.ordemAgrupamento; }));

    for (var i = 0; i < this.pedido.itensPedido.length; i++) {
      this.pedido.itensPedido[i].exibeLinhaSeparacao = false;

      this.pedido.itensPedido[i].id = i + 1;
      if (i < (this.pedido.itensPedido.length - 1)) {
        if (this.pedido.itensPedido[i].ordemAgrupamento < this.pedido.itensPedido[i + 1].ordemAgrupamento) {
          this.pedido.itensPedido[i].exibeLinhaSeparacao = true;
        }
      }
      if (this.pedido.itensPedido[i].ordemAgrupamento == this.ultimoAgrupamento && i == (this.pedido.itensPedido.length - 1)) {
        this.pedido.itensPedido[i].ultimoItem = true;
      }
      else {
        this.pedido.itensPedido[i].ultimoItem = false;
      }
    }

    this.consultaTaxaDistancia(function () {

      localStorage.setItem(self.chaveLocalStorage, JSON.stringify(self.pedido));
      self.setFocusItemPedido();

      if (somaQuantidades > 0.95 && somaQuantidades <= 1) {
        somaQuantidades = 1;
      }
      self.pedidoValido = Number.isInteger(somaQuantidades);
    });

    this.pedido = this.aplicarDesconto(this.pedido);
    this.aplicarDescontoDireto();
  }


  consultaTaxaDistancia(callback = null) {
    this.espera(true);
    var self = this;

    if (this.pedido.balcao == true) {

      if (typeof callback === "function") {
        self.pedido.taxaEntrega = 0;
        callback();
        self.espera(false);
      }

    } else {

      if ((self.pedido.taxaEntrega || 0) == 0 && this.pedido.cliente.enderecos.length > 0) {
        var end = new List<Endereco>(this.pedido.cliente.enderecos).FirstOrDefault(e => e.selecionado == true);

        if (end == undefined) {
          end = new List<Endereco>(this.pedido.cliente.enderecos).FirstOrDefault();
        }

        var enderecoDestino = `${end.logradouro} ${end.numero} ${end.bairro} ${end.cidade} ${end.uf}`;

        var geoCoder = new google.maps.Geocoder();


        geoCoder.geocode({ address: enderecoDestino }, (results, status) => {

          if (status == "OK") {
            self.latlng = results[0].geometry.location;

            self.taxaServico.consultaTaxaBanco(self.latlng.lat(), self.latlng.lng()).subscribe(
              taxa => {

                if (taxa == null || taxa == undefined || (taxa.id || 0) == 0) {
                  self.mensagem = "Este endereço não está em nossa área de cobertura";
                  self.espera(false);
                  if (typeof callback === "function")
                    callback();
                } else {
                  self.mensagem = null;
                  if (typeof callback === "function") {
                    self.pedido.taxaEntrega = taxa.taxaValor;
                    callback();
                    self.espera(false);
                  }
                }
              });
          }
        });
      }
      else {
        callback();
        self.espera(false);
      }


    }

  }



  public parseFloat(n: string): number {
    return parseFloat(n);
  }

  limpaProdutoSelecionado() {
    this.produtoSelecionado = new ProdutoSabor();
    this.quantidade = 0;
    this.quantidadeFracionada = "";
    this.observacao = "";

  }

  public irParaResumo() {
    this.espera(true);
    this.atualizaPedido();
    this.router.navigate([`/resumo-pedido/${this.numeroPedido}`]);
    this.espera(false);
  }

  public espera(ativa: boolean) {
    this.ativar_spinner = ativa;
  }

  public setBalcao() {
    this.atualizaPedido();
  }

  private iniciarPedido() {
    if (!this.pedido)
      this.pedido = new Pedido();

    if (!this.pedido.cliente)
      this.pedido.cliente = new Cliente();

    if (!this.pedido.cliente.enderecos)
      this.pedido.cliente.enderecos = [];

    if (!this.pedido.cliente.telefones)
      this.pedido.cliente.telefones = [];

    if (this.cliente)
      this.pedido.cliente = this.cliente;

    /*
    if (this.telefone != null) {
      if (!this.ehObjetoVazio(this.telefone) && !this.ContemObjeto(this.telefone, this.pedido.cliente.telefones)) {
        //this.pedido.cliente.telefones.push(this.telefone);
        //new List<Telefone>(this.pedido.cliente.telefones);
        this.pedido.cliente.telefones = new List<Telefone>(this.pedido.cliente.telefones).Where(e => e.ddd != this.telefone.ddd && e.numero != this.telefone.numero).Distinct().ToArray();
        this.pedido.cliente.telefones.push(this.deepClone(this.telefone));
      }
    }

    if (this.endereco != null) {
      if (!this.ehObjetoVazio(this.endereco) && !this.ContemObjeto(this.endereco, this.pedido.cliente.enderecos)) {
        new List<Endereco>(this.pedido.cliente.enderecos).ForEach(e => { e.selecionado = false });
        this.pedido.cliente.enderecos = new List<Endereco>(this.pedido.cliente.enderecos).Where(e => e.cep != this.endereco.cep).Distinct().ToArray();
        this.pedido.cliente.enderecos.push(this.deepClone(this.endereco));
      }
    }
    */

    this.pedido.numeroPedido = this.numeroPedido;
    this.pedido.status = StatusEnum.EmAndamento;
  }

  private setFormaPagamento() {
    this.pedido.formaPagamento = parseInt(this.formaPagamento);
    localStorage.setItem(this.chaveLocalStorage, JSON.stringify(this.pedido));
    //this.formaPagamento = formaPagamentoId;
  }

  public pagamentoCartao() {
    this.trocoPara = 0;
    this.setFormaPagamento();
  }

  public focoTrocoPara() {
    var self = this;
    var to = window.setTimeout(function () {
      $('#txtTrocoPara').focus();

      var valor = Math.ceil(self.valorPedido + (self.pedido.balcao ? 0 : self.pedido.taxaEntrega));
      while (valor % 10 > 1) {
        valor++;
      }
      self.trocoPara = 0;
      $('#txtTrocoPara').val(0);
      clearInterval(to);
    }, 500);

    this.setFormaPagamento();

  }

  private aplicarDesconto(pedido: Pedido): Pedido {

    this.subTotalPedido = this.valorPedido;

    if (pedido.cliente.saldo != 0) {
      if (pedido.cliente.saldo > 0) {
        if (this.valorPedido >= pedido.cliente.saldo) {
          this.valorDesconto = pedido.cliente.saldo;
          this.valorPedido -= pedido.cliente.saldo;
        }
        else {
          this.valorDesconto = this.valorPedido;
          pedido.cliente.saldo -= this.valorPedido;
          this.valorPedido = 0;
        }
      }
      else {
        this.valorPedido += (pedido.cliente.saldo * -1);
        this.valorDesconto = (pedido.cliente.saldo * -1);
      }
    }

    return pedido;
  }

  public finalizar() {

    this.atualizaPedido();
    this.espera(true);

    this.pedido.subTotal = this.subTotalPedido;
    this.pedido.valorTotal = this.valorPedido + this.pedido.taxaEntrega;
    this.pedido.descontoOuCobranca = this.valorDesconto;

    if (this.pedido.formaPagamento == 4) {
      this.pedido.valorTroco = this.trocoPara - this.pedido.valorTotal;
    }

    this.pedido.status = StatusEnum.Forno;
    this.pedido.origemPedido = 1;
    this.pedido.clienteId = this.pedido.cliente.id;
    //imprimir via forno
    //imprimir via motoboy
    //gravar Pedido no banco de dados
    this.pedido.usuarioId = this.usuarioServico.usuario.id;

    this.pedido.pagamentoPedido = new PagamentoPedido();

    switch (this.pedido.formaPagamento) {
      case 1:
        this.pedido.pagamentoPedido.valorCartao = this.pedido.valorTotal;
        break;
      case 2:
        this.pedido.pagamentoPedido.valorCartao = this.pedido.valorTotal;
        break;
      case 3:
        this.pedido.pagamentoPedido.valorTicket = this.pedido.valorTotal;
        break;
      case 4:
        this.pedido.pagamentoPedido.valorDinheiro = this.pedido.valorTotal;
        break;
    }

    this.pedidoServico.salvar(this.pedido).subscribe(
      retorno => {

        this.pedido.id = retorno.id;
        this.imprimirViaServico();
        // apagar pedido do localstorage
        localStorage.removeItem(this.chaveLocalStorage);

        this.espera(false);

        if (this.pedido.balcao) {
          $(() => {
            $('#txtClienteNome').focus();
          });

          this.permiteNomeCliente = true;
          this.numeroPedido = parseInt(this.datePipe.transform(new Date(), "yyyyMMddHHmmss", "-0300"));
          this.iniciarPedido();
          this.pedido.id = 0;
          this.pedido.balcao = true;
          this.pedido.cliente.somenteTaxaBalcao = true;
          this.pedido.cliente.balcao = true;
          this.pedido.cliente.saldo = 0;
          this.pedido.cliente.nome = "";
          this.valorPedido = 0;
          this.pedido.observacaoGeral = "";
          this.pedido.observacaoGeralCliente = "";
          this.formaPagamento = "0";
          this.ehBroto = false;

          this.chaveLocalStorage = PedidoConstants.CHAVE_PEDIDO_LOCAL_STORAGE.format({ numeroPedido: this.numeroPedido });
          localStorage.setItem(this.chaveLocalStorage, JSON.stringify(this.pedido));
          this.listaItensPedido = [];
          this.subTotalPedido = 0;
          this.router.navigate(['/faz-pedido-balcao/true']);

        }
        else
          this.router.navigate(['/identifica-cliente']);
      },
      erro => {
        this.processandoPedido = false;
        this.espera(false);
        //this.msg= "Ocorreu um erro ao finalizar o pedido."
      }
    );
  }

  imprimirViaServico() {

    this.pedido.valorTotal = this.valorPedido + this.pedido.taxaEntrega;

    if (this.pedido.formaPagamento == 4) {
      this.pedido.valorTroco = this.trocoPara - this.pedido.valorTotal;
    }

    this.pedido.clienteId = this.pedido.cliente.id;
    this.pedido.usuarioId = this.usuarioServico.usuario.id;

    this.pedidoServico.montarImpressaoPedido(this.pedido).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            this.processandoPedido = false;
            console.log(retorno);
            this.pedidoServico.imprimindo = false;
          },
          error => {
            this.pedidoServico.imprimirViaServico(impressao).subscribe(
              retorno => {
                this.processandoPedido = false;
                console.log(retorno);
                this.pedidoServico.imprimindo = false;
              },
              error => { this.processandoPedido = false; }
            );
            this.mensagem = error;
          }
        )
      },
      error => {
        this.mensagem = error;
        this.processandoPedido = false;
      }
    )

  }

  public buscarUltimoPedido() {
    this.espera(true);
    this.pedidoServico.buscarUltimoPedido(this.pedido.cliente.id).subscribe(
      retorno => {
        if (retorno != null) {
          retorno.numeroPedido = this.numeroPedido;
          this.pedido = retorno;

          this.chaveLocalStorage = PedidoConstants.CHAVE_PEDIDO_LOCAL_STORAGE.format({ numeroPedido: this.numeroPedido });
          localStorage.setItem(this.chaveLocalStorage, JSON.stringify(this.pedido));
        }
        else {
          alert('Cliente nunca fez pedido.');
        }
        this.espera(false);
      },
      erro => {
        this.espera(false);
        //this.msg= "Ocorreu um erro ao finalizar o pedido."
      }
    );
  }

  public setFocusQuantidade() {
    if (this.produtoSelecionado.tipoProduto != undefined) {
      if (this.produtoSelecionado.tipoProduto == 4) {
        //const qtdeBebiba = this.fazPedidoForm.nativeElement['qtdeBebida'];
        //qtdeBebiba.focus();
        this.qtdeBebidaInput.nativeElement.focus();
      }
      else {
        this.qtdeOutrosInput.nativeElement.focus();
        //const qtdeOutros = this.fazPedidoForm.nativeElement['qtdeOutros'];
        //qtdeOutros.focus();
      }
    }
  }

  public somaTotalPorItem(agrupamento) {
    var itens = this.listaItensPedido.filter(ip => ip.ordemAgrupamento == agrupamento);

    let total = 0;

    itens.forEach((item) => {
      total += Number(item.preco);
    });

    return total;
  }

  public atualizaTaxa() {
    this.espera(true);
    var end = new List<Endereco>(this.pedido.cliente.enderecos).FirstOrDefault(e => e.selecionado == true);
    var enderecoDestino = `${end.logradouro} ${end.numero} ${end.bairro} ${end.cidade} ${end.uf}`;

    var geoCoder = new google.maps.Geocoder();
    var latlng: google.maps.LatLng;

    geoCoder.geocode({ address: enderecoDestino }, (results, status) => {
      if (status == "OK") {
        latlng = results[0].geometry.location;

        end.lat = latlng.lat();
        end.lng = latlng.lng();

        this.clienteService.atualizaEndereco(end)
          .subscribe(retorno => {
            this.taxaServico.consultaTaxaBanco(end.lat, end.lng).subscribe(
              taxa => {
                if (taxa != null) {
                  this.pedido.taxaEntrega = taxa.taxaValor;
                  this.mensagem = "";
                }

                this.espera(false);
              });
          }, err => {
            this.espera(false);
            console.log(err.error);
          });
      }
    });
  }

  salvarNovaTaxa() {
    if (confirm(`Deseja realmente atualizar o pedido para a taxa ${this.novaTaxa.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}?`)) {
      this.pedido.taxaEntrega = this.novaTaxa;
      $('#modalInformarTaxa').modal('hide');
      this.novaTaxa = 0;
    }
  }

  abrirNovaTaxa() {
    $(() => {
      $('#modalInformarTaxa').modal('show');
    });
  }

  aplicarDescontoDireto() {
    this.subTotalPedido = this.valorPedido;

    this.subTotalPedido = this.subTotalPedido - this.valorDescontoInformado;
    this.valorPedido = this.valorPedido - this.valorDescontoInformado;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {

    if (event.key === 'F2' && !this.processandoPedido) {
      if (this.pedidoValido && this.listaItensPedido.length > 0) {
        this.processandoPedido = true;
        this.finalizar();
      }
    }
  }
}
