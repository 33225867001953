import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router";
import { PedidoServico } from "../../servicos/pedido/pedido.servico";
import { Pedido, StatusEnum } from "../../model/pedido";
import { List } from "linqts";
import { EntregadorServico } from "../../servicos/entregador/entregador.servico";
import { Entregador, ContratoEnum } from "../../model/entregador";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import { isNullOrUndefined } from "util";
import { DatePipe } from "@angular/common";
import { PagamentoPedido } from "../../model/pagamentoPedido";

//variavel para utilizar jquery
declare var $: any;
declare var require: any;

loadCldr(
  require("cldr-data/main/pt/numbers.json"),
  require("cldr-data/main/pt/ca-gregorian.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/pt/timeZoneNames.json"),
  require('cldr-data/supplemental/weekdata.json') // To load the culture based first day of week
);

@Component({
  selector: "app-detalhe-pedido",
  templateUrl: "./detalhe-pedido.component.html",
  styleUrls: ["./detalhe-pedido.component.css"]
})
export class DetalhePedidoComponent implements OnInit {

  public pedidoSelecionado: Pedido;
  public numeroPedido: number;
  public ativar_spinner: boolean = false;
  public pesquisouPedido: boolean = false;
  public entregadorSelecionado: Entregador = new Entregador();
  public entregadorSelecionadoId: number;
  public listaEntregadoresAtivos: Entregador[] = [];
  public ContratoEnum = ContratoEnum;
  public minDate: Date = new Date("01/01/2021");
  public maxDate: Date = new Date("12/31/2099");
  public dateValue: Date = new Date();
  public dataSelecionada: Date = new Date();
  public StatusEnum = StatusEnum;
  public valorTotalPendente: number;

  constructor(private pedidoServico: PedidoServico, private router: Router, private entregadorServico: EntregadorServico, public datepipe: DatePipe) {
    this.preencheListaEntregadores();
    //this.dateValue = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
  }

  ngOnInit(): void {
    $(() => {
      $('#txtNumeroPedido').focus();
    });
  }

  pesquisarPedido(event) {
    this.ativar_spinner = true;
    this.pesquisouPedido = false;

    if (event.keyCode == 13) {
      let latest_date = this.datepipe.transform(this.dataSelecionada, 'yyyy-MM-dd');

      if (isNullOrUndefined(this.numeroPedido) || isNullOrUndefined(latest_date)) {
        this.pedidoSelecionado = null;
        this.pesquisouPedido = true;
        this.ativar_spinner = false;
        return;
      }
      if (this.numeroPedido > 0) {
        this.pedidoServico.consultaPedidoPorNumero(this.numeroPedido).subscribe(
          pedido => {
            this.pedidoSelecionado = pedido;

            if (this.pedidoSelecionado.pagamentoPedido == null) {
              this.pedidoSelecionado.pagamentoPedido = new PagamentoPedido();
              this.pedidoSelecionado.pagamentoPedido.valorCartao = 0;
              this.pedidoSelecionado.pagamentoPedido.valorDinheiro = 0;
              this.pedidoSelecionado.pagamentoPedido.valorTicket = 0;
              this.pedidoSelecionado.pagamentoPedido.valorFiado = 0;
            }

            this.valorTotalPendente = this.pedidoSelecionado.valorTotal - this.pedidoSelecionado.pagamentoPedido.valorCartao - this.pedidoSelecionado.pagamentoPedido.valorDinheiro - this.pedidoSelecionado.pagamentoPedido.valorTicket - this.pedidoSelecionado.pagamentoPedido.valorFiado - this.pedidoSelecionado.valorCortesia;

            if (this.pedidoSelecionado.balcao) {
              $(() => {
                $('#txtTroco').focus();
              });
            }
            else {
              $(() => {
                $('#lstEntregadores').focus();
              });
            }

            //self.mensagem = null;
            this.ativar_spinner = false;
            //self.updateTooltip();
          },
          erro => {
            this.pedidoSelecionado = null;
            //self.mensagem = erro.error;
            this.ativar_spinner = false;
            this.pesquisouPedido = true;
          }
        );
      }
    }
    else {
      this.ativar_spinner = false;
    }


    //$(() => {
    //  $('#modalDetalhePedido').modal('show');
    //});
  }

  preencheListaEntregadores() {
    var self = this;

    this.entregadorServico.obterTodosEntregadores().subscribe(
      entregadores => {
        self.listaEntregadoresAtivos = new List<Entregador>(entregadores).Where(e => e.ativo == true).ToArray();
        self.listaEntregadoresAtivos.map(e => {
          e.search_label = `${e.nome} ${e.apelido} ${e.telefone} ${e.contrato}`;
          return e;
        });

      },
      erro => { console.log(erro.error); }//self.mensagem = erro.error; }
    );
  }

  customSearchFn(term: string, item: any) {

    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    var ehNumero = !isNaN(Number(term));

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      if (ehNumero) {
        let search = item['codigo'];
        isWordThere.push(search == Number(arr_term));
      }
      else {
        let search = item['search_label'].toLowerCase();
        isWordThere.push(search.indexOf(arr_term) != -1);
      }

    });

    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }

  informarValorPago() {

    var valorPago = this.pedidoSelecionado.pagamentoPedido.valorCartao + this.pedidoSelecionado.pagamentoPedido.valorDinheiro + this.pedidoSelecionado.pagamentoPedido.valorTicket + this.pedidoSelecionado.pagamentoPedido.valorFiado + this.pedidoSelecionado.valorCortesia + this.pedidoSelecionado.pagamentoPedido.valorPIX;

    if (valorPago <= 0)
      this.valorTotalPendente = this.pedidoSelecionado.valorTotal;
    else
      this.valorTotalPendente = this.pedidoSelecionado.valorTotal - valorPago;

    if (valorPago > this.pedidoSelecionado.valorTotal) {
      this.pedidoSelecionado.valorCaixinha = valorPago - this.pedidoSelecionado.valorTotal;
      this.pedidoSelecionado.valorPago = valorPago - this.pedidoSelecionado.valorCaixinha;
    }
    else {
      this.pedidoSelecionado.valorCaixinha = 0;
      this.pedidoSelecionado.valorPago = valorPago;
    }
  }

  finalizarPedido(pedido: Pedido) {
    this.ativar_spinner = true;
    var self = this;
    var status = StatusEnum.Entregue;

    if (confirm(`Deseja realmente finalizar o Pedido ${pedido.numeroPedidoDia}?`)) {
      self.informarValorPago();

      var valorPago = this.pedidoSelecionado.pagamentoPedido.valorCartao + this.pedidoSelecionado.pagamentoPedido.valorDinheiro + this.pedidoSelecionado.pagamentoPedido.valorTicket + this.pedidoSelecionado.pagamentoPedido.valorFiado + this.pedidoSelecionado.valorCortesia + this.pedidoSelecionado.pagamentoPedido.valorPIX;

      if (pedido.valorTotal != valorPago) {
        if (!confirm('O valor pago informado está diferente do valor cobrado, deseja continuar?')) {
          this.ativar_spinner = false;
          return;
        }
      }

      pedido.status = status;

      // chamar servico para alterar os status de acordo com o status finalizador
      this.pedidoServico.atualizar(pedido).subscribe(
        pedidoRetorno => {
          if (confirm('Deseja imprimir o pedido finalizado?')) {
            self.imprimirFechamentoComanda(pedido);
          }

          $(() => {
            $('#txtNumeroPedido').focus();
          });

          self.numeroPedido = null;
          self.pedidoSelecionado = null;
          self.pesquisouPedido = false;
          this.ativar_spinner = false;
        },
        erro => {
          console.log(erro);
          this.ativar_spinner = false;
        }
      );

    }
    else {
      this.ativar_spinner = false;
    }
  }

  async imprimirPedido(pedido: Pedido) {
    while (this.pedidoServico.imprimindo) {
      await this.sleep(1000);
    }

    this.pedidoServico.imprimindo = true;

    this.pedidoServico.montarImpressaoPedido(pedido).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            console.log(retorno);
            this.pedidoServico.imprimindo = false;
          },
          error => {
            console.log(error);
            this.pedidoServico.imprimindo = false;
          }
        )
      },
      error => {
        console.log(error);
      }
    )
  }

  async imprimirFechamentoComanda(pedido: Pedido) {
    while (this.pedidoServico.imprimindo) {
      await this.sleep(1000);
    }

    this.pedidoServico.imprimindo = true;

    this.pedidoServico.montarImpressaoFechamentoComanda(pedido).subscribe(
      impressao => {
        this.pedidoServico.imprimirViaServico(impressao).subscribe(
          retorno => {
            console.log(retorno);
            this.pedidoServico.imprimindo = false;
          },
          error => {
            console.log(error);
            this.pedidoServico.imprimindo = false;
          }
        )
      },
      error => {
        console.log(error);
      }
    )
  }

  focusCartao(campoFiado, campoCortesia) {
    if (this.pedidoSelecionado.balcao) {
      campoCortesia.focus()
    }
    else {
      campoFiado.focus()

    }
  }

  focusCortesia(campoCaixinha) {
    if (this.pedidoSelecionado.balcao) {
      this.finalizarPedido(this.pedidoSelecionado);
    }
    else {
      campoCaixinha.focus()

    }
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
