import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfiguracaoSistema } from "../../model/ConfiguracaoSistema";
import { ShortLink } from "../../model/shortlink";

@Injectable({
  providedIn: "root"
})
export class ConfiguracaoSistemaServico {

  private baseURL: string;

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  public obterTodos(): Observable<ConfiguracaoSistema[]> {
    return this.http.get<ConfiguracaoSistema[]>(this.baseURL + "api/configuracaoSistema/obterTodos", { headers: this.headers });
  }

  public ObterPorNome(configuracaoSistema: ConfiguracaoSistema): Observable<ConfiguracaoSistema[]> {
    return this.http.post<ConfiguracaoSistema[]>(this.baseURL + "api/configuracaoSistema/obterConfiguracaoPorNome", JSON.stringify(configuracaoSistema), { headers: this.headers });
  }

  public salvarTempoEstimado(tempoEntrega: number, tempoBalcao: number): Observable<true> {
    return this.http.post<true>(this.baseURL + "api/configuracaoSistema/salvarTempoEstimado/" + tempoEntrega + '/' + tempoBalcao, null, { headers: this.headers });
  }

  public shortlink(url: string): Observable<ShortLink> {
    return this.http.post<ShortLink>(this.baseURL + "api/configuracaoSistema/shortlink", JSON.stringify({ url: url }), { headers: this.headers });
  }

  public shortlinkObter(url: string): Observable<ShortLink> {
    return this.http.post<ShortLink>(this.baseURL + "api/configuracaoSistema/shortlinkObter", JSON.stringify({ url: url }), { headers: this.headers });
  }

}
