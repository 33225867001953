/// <reference types="@types/googlemaps" />

import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Taxa } from "../../model/taxa";
import { Endereco } from "../../model/endereco";


@Injectable({
  providedIn: "root"
})
export class TaxaServico implements OnInit {

  private baseURL: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {
  }


  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };


  salvarTaxas(taxa: Taxa) {
    return this.http.post(this.baseURL + 'api/taxa', JSON.stringify(taxa), { headers: this.headers });
  }


  remover(taxaId: number) {
    return this.http.delete(this.baseURL + `api/taxa/${taxaId}`, { headers: this.headers });
  }

  listarTaxas(): Observable<Taxa[]> {
    return this.http.get<Taxa[]>(this.baseURL + 'api/taxa');
  }

  consultaTaxaBanco(lat: number, lng: number): Observable<Taxa> {
    return this.http.post<Taxa>(this.baseURL + 'api/taxa/consulta-taxa', JSON.stringify({ lat: lat, lng: lng }), { headers: this.headers });
  }

  //não testado ainda
  consultaTaxa(endereco: string, callback: (taxa: Taxa) => void): void {

    var geoCoder = new google.maps.Geocoder();
    var latlng: google.maps.LatLng;
    geoCoder.geocode({ address: endereco }, (results, status) => {
      debugger;
      if (status == "OK") {
        latlng = results[0].geometry.location;
        this.consultaTaxaBanco(latlng.lat(), latlng.lng()).subscribe(
          taxa => {
            debugger;
            if (typeof callback === "function")
              callback(taxa);
          });
      }
    });
  }
}
