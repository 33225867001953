import { Component, OnInit } from "@angular/core"
//import { Ingrediente } from "../model/ingrediente";
//import { IngredienteServico } from "../servicos/ingrediente/ingrediente.servico";
import { Router } from "@angular/router";

@Component({
  selector: "app-movimentacao-financeira",
  templateUrl: "./movimentacao-financeira.component.html",
  styleUrls: ["./movimentacao-financeira.component.css"]
})
export class MovimentacaoFinanceiraComponent implements OnInit {
  constructor(private router: Router) {

  }

  ngOnInit(): void {

  }


}
