import { Component, OnInit } from "@angular/core";
import { Ingrediente } from "../../model/ingrediente";
import { IngredienteServico } from "../../servicos/ingrediente/ingrediente.servico";
import { Router } from "@angular/router";

@Component({
  selector: "pesquisa-ingrediente",
  templateUrl: "./pesquisa.ingrediente.component.html",
  styleUrls: ["./pesquisa.ingrediente.component.css"]
})

export class PesquisaIngredienteComponent implements OnInit {

  public ingredientes: Ingrediente[];

  ngOnInit(): void {

  }

  constructor(private ingredienteServico: IngredienteServico, private router: Router) {
    this.ingredienteServico.obterTodosIngredientes()
      .subscribe(
        ingredientes => {
          this.ingredientes = ingredientes;
        },
        err => {
          console.log(err.error);
        }
      )
  }

  public adicionarIngrediente() {
    sessionStorage.setItem('ingredienteSession', '');
    this.router.navigate(['/ingrediente']);
  }

  public excluirIngrediente(ingrediente: Ingrediente) {
    var retorno = confirm("Deseja realmente excluir o ingrediente selecionado?");
    if (retorno == true) {
      this.ingredienteServico.excluir(ingrediente).subscribe(
        ingredientes => {
          this.ingredientes = ingredientes;
        },
        err => {
          console.log(err.error);
        }
      );
    }
  }

  public editarIngrediente(ingrediente: Ingrediente) {
    sessionStorage.setItem('ingredienteSession', JSON.stringify(ingrediente));
    this.router.navigate(['/ingrediente']);
  }
}
