import { TipoProduto } from "./tipoProduto";
import { SaborProduto } from "./saborProduto";
import { Sabor } from "./sabor";

export class Produto {
  id: number;
  descricao: string;
  tipoProdutoId: number;
  preco: number;
  tipoProduto: TipoProduto;
  saboresProduto: SaborProduto[];
  sabores: Sabor[];
  nomeArquivo: string;
  precoOriginal: number;
  quantidade: number;
  quantidadeFracionada: string;
}
