import { Cliente } from "./cliente";
import { Usuario } from "./usuario";

export class InjecaoSaldo {
  id: number;
  valor: number;
  data: Date;
  clienteId: number;
  cliente: Cliente
  usuarioId: number;
  usuario: Usuario;
}
