import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Mercadoria } from "../../model/mercadoria";

@Injectable({
  providedIn: "root"
})
export class MercadoriaServico implements OnInit {
  private baseURL: string;
  public mercadorias: Mercadoria[];

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {
    this.mercadorias = [];
  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  public cadastrar(mercadoria: Mercadoria): Observable<Mercadoria> {
    return this.http.post<Mercadoria>(this.baseURL + "api/mercadoria", JSON.stringify(mercadoria), { headers: this.headers });
  }

  public salvar(mercadoria: Mercadoria): Observable<Mercadoria> {
    return this.http.post<Mercadoria>(this.baseURL + "api/mercadoria/salvar", JSON.stringify(mercadoria), { headers: this.headers });
  }

  public excluir(mercadoria: Mercadoria): Observable<Mercadoria[]> {
    return this.http.post<Mercadoria[]>(this.baseURL + "api/mercadoria/excluir", JSON.stringify(mercadoria), { headers: this.headers });
  }

  public obterTodasMercadorias(): Observable<Mercadoria[]> {
    return this.http.get<Mercadoria[]>(this.baseURL + "api/mercadoria");
  }

  public obterMercadoria(mercadoria: Mercadoria): Observable<Mercadoria[]> {
    return this.http.post<Mercadoria[]>(this.baseURL + "api/mercadoria/obter-por-nome", JSON.stringify(mercadoria), { headers: this.headers });
  }
}
