import { Injectable, Inject, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { RegistroFinanceiro } from "../../model/registroFinanceiro";
import { MovimentacaoFinanceira } from "../../model/movimentacaoFinanceira";
import { DadosImpressao } from "../../model/dadosImpressao";

@Injectable({
  providedIn: "root"
})

export class RegistroFinanceiroServico implements OnInit {

  private baseURL: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseURL = baseUrl;
  }

  ngOnInit(): void {
    
  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('content-type', 'application/json');
  };

  public obterRegistroAtual(): Observable<RegistroFinanceiro> {
    return this.http.get<RegistroFinanceiro>(this.baseURL + "api/registrofinanceiro/obter-registro-atual");
  }

  public salvar(registroFinanceiro: RegistroFinanceiro, usuarioId: number): Observable<RegistroFinanceiro> {
    return this.http.post<RegistroFinanceiro>(this.baseURL + "api/registrofinanceiro/salvar/" + usuarioId, JSON.stringify(registroFinanceiro), { headers: this.headers });
  }

  public salvarMovimentacaoFinanceira(movimentacaoFinanceira: MovimentacaoFinanceira): Observable<MovimentacaoFinanceira> {
    return this.http.post<MovimentacaoFinanceira>(this.baseURL + "api/registrofinanceiro/salvar-movimentacao-financeira", JSON.stringify(movimentacaoFinanceira), { headers: this.headers });
  }

  public adicionarMassa(registroFinanceiroId: number, qtdeMassas: number): Observable<boolean> {
    return this.http.post<boolean>(this.baseURL + "api/registrofinanceiro/adicionar-massa/" + registroFinanceiroId + "/" + qtdeMassas, { headers: this.headers });
  }

  public imprimirLancamentoCaixa(movimentacaoFinanceira: MovimentacaoFinanceira): Observable<DadosImpressao> {
    return this.http.post<DadosImpressao>(this.baseURL + "api/registrofinanceiro/imprimir-lancamento-caixa/", JSON.stringify(movimentacaoFinanceira), { headers: this.headers });
  }
}
