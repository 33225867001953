import { Component, OnInit } from "@angular/core"
import { Receita } from "../../model/receita";
import { ReceitaServico } from "../../servicos/receita/receita.servico";
import { Router } from "@angular/router";
import { SaborServico } from "../../servicos/sabor/sabor.servico";
import { MercadoriaServico } from "../../servicos/mercadoria/mercadoria.servico";
import { Mercadoria } from "../../model/mercadoria";
import { Sabor } from "../../model/sabor";

@Component({
  selector: "app-receita",
  templateUrl: "./cadastro.receita.component.html",
  styleUrls: ["./cadastro.receita.component.css"]
})
export class CadastroReceitaComponent implements OnInit {
  public receita: Receita = null;
  public ativar_spinner: boolean;
  public mensagem: string;
  public receitaExistente: boolean = false;
  public mercadorias: Mercadoria[];
  public sabores: Sabor[];
  public saborSelecionado: Sabor = null;
  public mercadoriaSelecionada: Mercadoria = null;
  public receitas: Receita[] = [];
  pag: Number = 1;
  contador: Number = 5;

  constructor(private receitaServico: ReceitaServico, private router: Router, private saborServico: SaborServico, private mercadoriaServico: MercadoriaServico) {
    this.ativar_spinner = true;

    this.saborServico.obterTodosSabores()
      .subscribe(
        sabores => {
          this.sabores = sabores;
          this.ativar_spinner = false;
        },
        err => {
          console.log(err.error);
          this.ativar_spinner = false;
        }
    )

    this.mercadoriaServico.obterTodasMercadorias()
      .subscribe(
        mercadorias => {
          this.mercadorias = mercadorias;
          this.ativar_spinner = false;
        },
        err => {
          console.log(err.error);
          this.ativar_spinner = false;
        }
      )
  }

  ngOnInit(): void {
    var receitaSession = sessionStorage.getItem('receitaSession');

    if (receitaSession) {
      this.receita = JSON.parse(receitaSession);

      this.ativar_spinner = true;

      this.receitaServico.obterReceitasPorSabor(this.receita.sabor.id)
        .subscribe(
          receitas => {
            this.receitas = receitas;
            this.receita = new Receita();
            this.receita.id = 0;
            this.ativar_spinner = false;
          },
          err => {
            console.log(err.error);
            this.ativar_spinner = false;
          }
        )
    }
    else {
      this.receita = new Receita();
      this.receita.id = 0;
    }

  }

  public inserir() {
    this.ativar_spinner = true;
    var receitaAux = new Receita();

    receitaAux.sabor = this.saborSelecionado;
    receitaAux.saborId = this.saborSelecionado.id;
    receitaAux.mercadoria = this.mercadoriaSelecionada;
    receitaAux.mercadoriaId = this.mercadoriaSelecionada.id;
    receitaAux.quantidade = this.receita.quantidade;

    this.receitas.push(receitaAux);

    this.mercadoriaSelecionada = null;
    this.receita.quantidade = null;

    this.ativar_spinner = false;
  }

  public remover(receita: Receita) {
    this.ativar_spinner = true;
    this.receitas = this.receitas.filter(r => r != receita);
    this.ativar_spinner = false;
  }

  public cadastrar() {
    this.ativarEspera();

    this.receitaServico.cadastrarTodas(this.receitas)
      .subscribe(
        receitaRetorno => {
          debugger;
          console.log(receitaRetorno);
          sessionStorage.removeItem('receitaSession');
          this.desativarEspera();
          this.router.navigate(['/pesquisar-receita']);
        },
        err => {
          this.mensagem = err.error;
          this.desativarEspera();
        }
      );
  }

  //public verificarReceitaExistente() {

  //  this.ativarEspera();
  //  this.receitaServico.obterReceita(this.receita)
  //    .subscribe(
  //      receitaRetorno => {
  //        if (receitaRetorno == null) {
  //          this.receitaExistente = false;
  //        }
  //        else {
  //          if (receitaRetorno.length > 0)
  //            this.receitaExistente = true;
  //          else
  //            this.receitaExistente = false;
  //        }

  //        this.desativarEspera();
  //      },
  //      err => {
  //        this.mensagem = err.error;
  //        this.desativarEspera();
  //      }
  //    );
  //}

  public ativarEspera() {
    this.ativar_spinner = true;
  }

  public desativarEspera() {
    this.ativar_spinner = false;
  }
}

