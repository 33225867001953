import { Telefone } from "./telefone";
import { Endereco } from "./endereco";
import { Pedido } from "./pedido";

export class Cliente {
  constructor() {
    this.telefones = [];
    this.enderecos = [];
    this.pedidos = [];
    this.somenteTaxaBalcao = false;
  }

  id: number;
  nome: string;
  cpfCnpj: string;
  email: string;
  telefones: Telefone[];
  enderecos: Endereco[];
  pedidos: Pedido[];
  saldo: number;
  somenteTaxaBalcao: boolean;
  balcao: boolean;
  observacao: string;
  //ativo: boolean;
}
